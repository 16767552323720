


import React, { Component } from 'react';
import { LeftCircleFilled, RightCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';

import './slider.css';

class Slider extends Component {
    constructor(props){
        super(props);

        this.state = {
            itemWidth: 322,
        };
    }

    mkMain() {
        let { itemWidth } = this.state;
        let { className, height, items, leftOnClick, rightOnClick, closeOnClick } = this.props;

        let component = <div className={"slider-wapper " + (className || "")}>
            <div className="slider" style={{height: height}}>
                <div className="left">
                    {/* 向左移动 */}
                    <div>
                        <LeftCircleFilled className="icon" onClick={(e) => {
                            let domRemotes = document.getElementById('dom_content');
                            if (domRemotes) {
                                // 最后一个元素在显示区的最左边，不能左移
                                let value = (parseInt(domRemotes.style.left) || 0) - itemWidth;
                                let newIndex = -value / itemWidth;
                                let oldIndex = newIndex - 1;
                                if (value >= -itemWidth * (items.length - 1)) {
                                    domRemotes.style.left = `${value}px`;
                                } else {
                                    newIndex -= 1;
                                }
                                // console.log(`move left ${oldIndex} -> ${newIndex}`)
                                if (leftOnClick) {
                                    leftOnClick(e, newIndex, oldIndex)
                                }
                            }
                        }}/>
                    </div>
                    {/* 向右移动 */}
                    <div>
                        <RightCircleFilled className="icon" onClick={(e) => {
                            let domRemotes = document.getElementById('dom_content');
                            if (domRemotes) {
                                // 第一个元素在显示区的最左边，不能右移
                                let value = (parseInt(domRemotes.style.left) || 0) + itemWidth;
                                let newIndex = -value / itemWidth;
                                let oldIndex = newIndex + 1;
                                if (value <= 0) {
                                    domRemotes.style.left = `${value}px`;
                                } else {
                                    newIndex += 1;
                                }

                                // console.log(`move right ${oldIndex} -> ${newIndex}`)
                                if (rightOnClick) {
                                    rightOnClick(e, newIndex, oldIndex)
                                }
                            }
                        }}/>
                    </div>
                </div>
                <div className="middle">
                    <div className="content" id="dom_content">
                        {items}
                    </div>
                </div>
                <div className="right">
                    <CloseCircleFilled className="icon" onClick={(e) => {
                        if (closeOnClick) {
                            closeOnClick(e)
                        }
                    }}/>
                </div>
            </div>
        </div>

        return component;
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    render() {
        let component = this.mkMain();
        return component;
    }

}

export default Slider;