export let version = {
    software: "v1.0.6-beta",
    sip: "v1.0.8-beta"
}

export let config = {

    // 入会历史配置
    dialHistory: {
        saveMax: 5
    },

    // sipml配置
    sipml: {
        logLevel: "debug", // "info", "warn", "error", "debug"
    },

    // 上传配置
    upload: {
        accessId: "LTAI4GKyttiv78tHxMSY9eaB",
        accessKey: "mJWXUMQ8IMCrGkan6PHXX1Dvw7kywy",
        host: "https://online-log-collect.oss-cn-shenzhen.aliyuncs.com",
        policy: {
            "expiration": "2200-01-01T00:00:00.000Z", //设置该Policy的失效时间，超过这个失效时间之后，就没有办法通过这个policy上传文件了
            "conditions": [
                ["content-length-range", 0, 1048576000] // 设置上传文件的大小限制
            ]
        },
        dir: 'vmeeting/', // 上传目录
    },

    // 预览配置
    preview: {
        definition: {
            width: 1280, 
            height: 720,
        }
    },

    // 视频窗口配置
    video: {
        window: {
            float: {
                width: 320, 
                height: 240,
                margin: {
                    small: 16,
                    larger: 64,
                }
            },
            samll: {
                width: 322, 
                height: 182,
            },
            nickName: {
                showMode: 'hover', // always：总是显示 hover：鼠标悬浮显示
            },
        }
    },
}