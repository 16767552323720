
import { adapter } from "./adapter";

export var Kind = {
    NONE: 'none',
    AUDIO: 'a',
    VIDEO: 'v',
    ALL: 'all',
}

export var Dir = {
    NONE: 'none',
    SEND: 's',
    RECV: 'r',
    ALL: 'all',
}

export var ReportState = {
    STOP: 0,
    START: 1,
}

class Preview {
    constructor(localDomId = "dom_v_local", localPreviewDomId = "dom_v_local_preview") {
        this.__localDomId = localDomId;
        this.__localPreviewDomId = localPreviewDomId;

        this.__stream = null;
        this.__getMediaSuccessFun = null;
        this.__getMediaErrorFun = null;
    }

    get stream() {
        return this.__stream;
    }

    set onGetMediaSuccess (fun) {
        this.__getMediaSuccessFun = fun;
    }

    set onGetMediaError (fun) {
        this.__getMediaErrorFun = fun;
    }

    start(deviceId, local = false) {
        let domId = local ? this.__localDomId : this.__localPreviewDomId;
        let element = document.getElementById(domId);
        if (!element) {
            console.error("can't get element " + domId);
            return;
        }

        this.stop();

        console.info("video preview start. deviceId: ", deviceId);
        adapter.getUserMedia({ 
            video: {
                width: 1280, 
                height: 720,
                deviceId: { exact: deviceId }
            }, 
        }, (stream) => {
            // 成功的回调
            console.info(`attach DOM<${element.id}> to media stream<${stream.id}> of device<${deviceId}>`);
            window.attachMediaStream(element, stream)
            this.__stream = stream;
            if (this.__getMediaSuccessFun) {
                this.__getMediaSuccessFun(stream);
            }
        }, (error) => {
            // 失败的回调
            console.error(error);
            if (this.__getMediaErrorFun) {
                this.__getMediaErrorFun(error);
            }
        })
    }

    stop() {

        if (this.__stream) {
            console.info("video preview stop");
            if (this.__stream) {
                if(this.__stream.stop) {
                    this.__stream.stop(); // FF and other not yet updated browsers
                    console.log(`stream ${this.__stream.id} stopped`);
                } 
                else {
                    // updated browsers
                    let tracks = this.__stream.getTracks() || [];
                    tracks.forEach(track => {
                        if (track.stop){
                            track.stop();
                            console.log(`video preview stream stopped. ${this.__stream.id} ${track.kind} track`);
                        }
                    });
                }
            }
            this.__stream = null;
        }
    }
}

class Report {

    constructor(name) {
        this.__name = name;

        this.__peerConnection = null;
        this.__streams = [];
        this.__reports = [];

        this.__state = ReportState.STOP;
    }
    
    set peerConnection(pc) {
        this.__peerConnection = pc;
        return this;
    }

    set streams(streams) {
        this.__streams = streams;        
        return this;
    }

    set interval(interval) {
        this.__interval = interval;
        return this;
    }

    set reports(reps) {
        this.__reports = reps;
        return this;
    }

    testState(state) {
        return this.__state === state;
    }

    start() {
        this.stop();
        this.__timeout = setInterval(() => this.collect(), this.__interval * 1000)
        this.__state = ReportState.START;
        console.info(`start interval timer for ${this.__name} report`)
    }

    stop() {
        this.__state = ReportState.STOP;
        if (this.__timeout) {
            clearInterval(this.__timeout);
            console.info(`stop interval timer for ${this.__name} report`)
        }
    }

    collect() {
        let peerConnection = this.__peerConnection;
        try {
            this.__streams.forEach((stream, i) => {
                let tracks = stream.getTracks() || [];
                tracks.forEach((item, j) => {
                    try {
                        peerConnection.getStats(item)
                            .then(stats => {
                                let key = `${this.__name}Reports`;
                                let index = String.fromCharCode(i + 65) + ((j + 1) < 10 ? ("0" + (j + 1)) : (j + 1));
                                let track = this.__reports[key];
                                if (!track) {
                                    track = this.__reports[key] = {}
                                }
                                if (!track[index]) {
                                    track[index] = {};
                                }
                                // console.log(key)
        
                                stats.forEach(report => {
                                    if (report.type === "codec" || report.type === "track" || report.type === "local-candidate" || report.type === "remote-candidate") {
                                        report.msid = stream.id;
                                        track[index][report.type] = report;
                                        // console.log(key, report);
        
                                    } else if (report.type === "outbound-rtp") {
                                        let lastBytesSend = (track[index][report.type] && (track[index][report.type].bytesSent + track[index][report.type].headerBytesSent)) || 0
                                        report.outgoingBitRate = ((report.bytesSent + report.headerBytesSent - lastBytesSend) * 8 / this.__interval).toFixed(0)
                                        track[index][report.type] = report;
                                        // console.log(key, report)
                                        
                                    } else if (report.type === "inbound-rtp") {
                                        let lastBytesRecv = (track[index][report.type] && (track[index][report.type].bytesReceived + track[index][report.type].headerBytesReceived)) || 0
                                        report.incomingBitRate = ((report.bytesReceived + report.headerBytesReceived - lastBytesRecv) * 8 / this.__interval).toFixed(0)
                                        track[index][report.type] = report;
                                        // if (realKind === "a") {
                                        //     console.log(key, report)
                                        // }
                                    }
                                    // console.log(key, report);
                                })
                            })
                            .catch((error) => {

                            })
                    } catch (error) {

                    }
                })
            });
        } catch (error) {

        }
    }
}

export class Stream {

    constructor() {
        this.__peerConnection = null;

        this.__preview = new Preview();
        
        this.__streamGroups = {};
        this.__reports = {};
        
        [
            `${Kind.VIDEO}${Dir.RECV}`, `${Kind.VIDEO}${Dir.SEND}`, 
            `${Kind.AUDIO}${Dir.RECV}`, `${Kind.AUDIO}${Dir.SEND}`,
        ].forEach((name) => {
            this.__streamGroups[name] = {
                report: new Report(name),
                streams: [],
            }
        })
    }

    __getInfo(stream) {
        let sp = stream.id.split("_");
        if (sp.length === 3) {
            return {
                kind: sp[0],
                index: sp[1],
                id: sp[2],
            }
        } else {
            return {};
        }
    }

    set peerConnection(pc) {
        this.__peerConnection = pc;
        return this;
    }

    get preview() {
        return this.__preview;
    }

    add(stream, kind, dir) {
        let key = `${kind}${dir}`;
        console.info(`add ${key} stream`);
        let group = this.__streamGroups[key];
        if (group) {
            group.streams.push(stream);
        }
    }

    remove(stream) {
        for (let key in this.__streamGroups) {
            let groups = this.__streamGroups[key];
            let index = groups.streams.findIndex((item) => item.id === stream.id);
            if (index !== -1) {
                groups.streams.splice(index, 1)
                groups.report.streams = groups.streams;
                break;
            }
        }
    }

    clear() {
        for (let key in this.__streamGroups) {
            this.__streamGroups[key].streams = [];
        }
        this.__peerConnection = null;
    }

    start(fun, interval = 5, delay = 3) {

        if (this.debounce1) {
            clearTimeout(this.debounce1);
        }

        this.debounce1 = setTimeout(() => {
            for (let key in this.__streamGroups) {
                let group = this.__streamGroups[key];
                group.report.peerConnection = this.__peerConnection;
                group.report.streams = group.streams;
                group.report.interval = interval;
                group.report.reports = this.__reports;
                group.report.start();
            }
            this.__timeout = setInterval(() => fun(this.__reports), interval * 1000)
            this.debounce1 = null;
        }, delay * 1000)

    }

    stop(kind = Kind.NONE, dir = Dir.NONE, delay = 1) {
        if (this.debounce2) {
            clearTimeout(this.debounce2);
        }

        this.debounce2 = setTimeout(() => {
            let vrReport = this.__streamGroups[`${Kind.VIDEO}${Dir.RECV}`].report;
            let vsReport = this.__streamGroups[`${Kind.VIDEO}${Dir.SEND}`].report;
            let arReport = this.__streamGroups[`${Kind.AUDIO}${Dir.RECV}`].report;
            let asReport = this.__streamGroups[`${Kind.AUDIO}${Dir.SEND}`].report;
            if ((kind === Kind.VIDEO || kind === Kind.ALL) && (dir === Dir.RECV || dir === Dir.ALL)) {
                vrReport.stop();
            }

            if ((kind === Kind.VIDEO || kind === Kind.ALL) && (dir === Dir.SEND || dir === Dir.ALL)) {
                vsReport.stop();
            }
            
            if ((kind === Kind.AUDIO || kind === Kind.ALL) && (dir === Dir.RECV || dir === Dir.ALL)) {
                arReport.stop();
            }
            
            if ((kind === Kind.AUDIO || kind === Kind.ALL) && (dir === Dir.SEND || dir === Dir.ALL)) {
                asReport.stop();
            }

            if (vrReport.testState(ReportState.STOP) && vsReport.testState(ReportState.STOP)
                && arReport.testState(ReportState.STOP) && asReport.testState(ReportState.STOP)) {
                if (this.__timeout) {
                    clearInterval(this.__timeout);
                }
                this.clear();
            }
            this.debounce2 = null;
        }, delay * 1000);
    }

    attach(domId, stream, retry = 2) {
        let that = this;
        let element = document.getElementById(domId);
        if (element && stream) {
            console.info(`attach DOM<${element.id}> to media stream<${stream.id}>`);
            window.attachMediaStream(element, stream);
        } else if (element && !stream) {
            // console.info(`deattach DOM<${element.id}>`);
            window.attachMediaStream(element, null);
        } else if (!element) {
            if (retry > 0) {
                console.warn(`attach DOM<${domId}> <${element}> to media stream<${stream ? stream.id : null}> failed, retry ${retry}`);
                setTimeout(() => {
                    that.attach(domId, stream, retry - 1);
                }, 1000);
            } else {
                console.warn(`attach DOM<${domId}> <${element}> to media stream<${stream ? stream.id : null}> failed`);
            }
        } 
    }

    reattachVideoRecv(stream) {
        let info = this.__getInfo(stream);
        this.attach(`dom_v_remote${info.index}`, null);
        this.attach(`dom_v_remote${info.index}`, stream);
    }

    reattachVideoRecvById(id) {
        setTimeout(() => {
            let streams = this.__streamGroups[`${Kind.VIDEO}${Dir.RECV}`].streams;
            if (streams) {
                streams.forEach((stream) => {
                    let info = this.__getInfo(stream);
                    if (parseInt(id) === parseInt(info.index)) {
                        this.attach(`dom_v_remote${info.index}`, null);
                        this.attach(`dom_v_remote${info.index}`, stream);
                    }
                })
            }
        }, 1000);
    }

    reattachAllVideoRecv() {
        setTimeout(() => {
            let streams = this.__streamGroups[`${Kind.VIDEO}${Dir.RECV}`].streams;
            if (streams) {
                streams.forEach((stream) => {
                    this.reattachVideoRecv(stream);
                })
            }
        }, 1000);
    }
}