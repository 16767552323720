import {message as Message} from "antd";

let timeouts = {

};

function preproccess(config) {
    let { content, key, duration } = config;
    // 没有配置消失时间（秒），则默认为3
    if (!duration) {
        duration = 3;
    }
    // 没有设置key则默认为_default
    if (!key) {
        key = "_default";
    }
    if (timeouts[key]) {
        clearTimeout(timeouts[key])
    }

    /* antd的message模块有时候弹出了消息一直不消失，点击消息之后（猜是获得了焦点）才按照配置的duration消失。
    这里做个定时器，防止这种情况 */
    timeouts[key] = setTimeout(() => {
        Message.destroy();
        timeouts[key] = null;
    }, duration * 1000);

    return {
        content: content,
        key: key, 
        duration: duration,
    }
}

export let message =  {

}

message.success = function (content = "", duration = 3, key = "_default") {
    return Message.success(preproccess({content: content, duration: duration, key: key}));
}

message.error = function (content = "", duration = 5, key = "_default") {
    return Message.error(preproccess({content: content, duration: duration, key: key}));
}

message.info = function (content = "", duration = 3, key = "_default") {
    return Message.info(preproccess({content: content, duration: duration, key: key}));
}

message.warning = function (content = "", duration = 5, key = "_default") {
    return Message.warning(preproccess({content: content, duration: duration, key: key}));
}

message.warn = message.warning;

message.loading = function (content = "", duration = 60, key = "_default") {
    return Message.loading(preproccess({content: content, duration: duration, key: key}));
}

message.destroy = function() {
    return Message.destroy();
}