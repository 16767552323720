import React, { Component } from 'react';
import { connect } from "react-redux"
import { Button, Modal, Descriptions } from "antd";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../util/iconFont";

import "./detail.css";
import "../common/paragraph.css";

@withTranslation()
class Detail extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }

    componentDidMount() {

    }

    render() {
        let { t, visible, detail, living, conferenceAddrFun, liveAddrFun, copyOnClick, onClose } = this.props;
        let component = t('detail.none');
        if (detail) {
            component = <div className="info-detail">
                 <Descriptions className="descriptions" column={1}>
                    <Descriptions.Item label={t('detail.theme')}>{detail.Title}</Descriptions.Item>
                    <Descriptions.Item label={t('detail.code')}>
                        {detail.ConferenceCode}
                        <span className="operation"><Button type="link" 
                            onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "code", detail.ConferenceCode)
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item>
		            <Descriptions.Item label={t('detail.start_time')}>{detail.StartTime}</Descriptions.Item>
                    {/* <Descriptions.Item label={t('detail.creator')}>{creator}</Descriptions.Item> */}
                    <Descriptions.Item label={t('detail.addr')}>
                        {conferenceAddrFun(detail)}
                        <span className="operation"><Button type="link" 
                            onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "conference", conferenceAddrFun(detail))
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item>
                    {living ? <Descriptions.Item label={t('detail.live_addr')}>
                        {liveAddrFun(detail)}
                        <span className="operation"><Button type="link" 
                                onClick={(e) => {
                                if (copyOnClick) {
                                    copyOnClick(e, "live", liveAddrFun(detail))
                                }
                            }
                        }>{t('common.copy')}</Button></span>
                    </Descriptions.Item> : undefined}
                    
                </Descriptions>
            </div>
        }

        return <Modal
            className="detail"
            width={"50%"}
            title={<span className="title">
                <IconFont className="icon" type="icon-info-dark"/>
                <span>{t('detail.info')}</span>
            </span>}
            closable={true}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                {component}
            </div>
        </Modal>
    }
}


let mapState = (state) => ({
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Detail);