
import moment from "moment";

export function invalidTime(value) {
    if (value === "2200-01-01 00:00:00" || value === "1970-01-01 00:00:00") {
        return true;
    }
    return false;
}

export function getDateDiff(diff, now) {
    let result = moment(now * 1000).format("YYYY-MM-DD HH:mm:ss") + " ";
    if (diff >= 0) {
        const monthC = diff / ( 7 * 60 * 60 * 24 * 30 );
        const weekC = diff / ( 7 * 60 * 60 * 24 );
        const dayC = diff / ( 60 * 60 * 24 );
        const hourC = diff / ( 60 * 60 );
        const minC = diff / 60;
        
        if (monthC >= 1) {
            result += "(" + parseInt(monthC) + "个月前)";
        }else if (weekC >= 1) {
            result += "(" + parseInt(weekC) + "周前)";
        }else if (dayC >= 1) {
            result += "(" + parseInt(dayC) + "天前)";
        }else if (hourC >= 1) {
            result += "(" + parseInt(hourC) + "小时前)";
        }else if (minC >= 1) {
            result += "(" + parseInt(minC) + "分钟前)";
        }else{
            result += "(刚刚)";
        } 
    }
     
    return result;
}

export function getTimeDesc(value, base = "second") {
    let day = undefined;
    let hour = undefined;
    let minute = undefined;
    let second = undefined;
    switch(base){
        case "second":
            day = Math.floor(value / (3600 * 24));
            hour = Math.floor((value - day * 3600 * 24) / 3600);
            minute = Math.floor((value - day * 3600 * 24 - hour * 3600) / 60);
            second = value % 60;
            break;
        case "minute":
            day = Math.floor(value / (60 * 24));
            hour = Math.floor((value - day * 60 * 24) / 60);
            minute = Math.floor(value - day * 60 * 24 - hour * 60);
            break;
        default:
            break;
    }
    
    const content = (day ? `${day}天` : "") + (hour ? `${hour}小时` : "") + (minute ? `${minute}分` : "") + (second ? `${second}秒` : "")
    return content || "0秒";
}

export function getUrlParam (name, str) {
    const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
    const r = str.substr(1).match(reg);
    if (r != null) return decodeURIComponent(r[2]); return null;
}

export function printObject(o, sp = '\r\n', root = '', indent = 0) {
    let ret = ' '.repeat(Math.max(indent - 2, 0)) + (root.length > 0 ? `${root}:\r\n` : '');
    let s = Object.prototype.toString.call("");
    let i = Object.prototype.toString.call(0);
    let b = Object.prototype.toString.call(true);
    let a = Object.prototype.toString.call([]);
    let m = Object.prototype.toString.call({});
    o = o || {};
    for (let k in o){
        let value = o[k]
        let type = Object.prototype.toString.call(value);
        if (type === s || type === i || type === b) {
            ret += ' '.repeat(indent) + `${k}: ${value}${sp}` 
        } else if (type === a || type === m) {
            ret += printObject(value, sp, k, indent + 2)
        } else {
            // console.error(`unsupported value:${value} type:${type}`)
        }
    }

    return ret;
}

export function uploadInfo(user, login, version, adapter, mediaDevice) {
    let name = `${user.NickName}(${user.Account})`
    let newProfile = Object.assign({}, user)
    newProfile.NickName = encodeURI(newProfile.NickName);
    let info = "" 
        + `[Profile]\r\n${printObject(newProfile)}\r\n` 
        + `[Login]\r\n${printObject(login)}\r\n` 
        + `[Version]\r\n${printObject(version)}\r\n`
        + `[Explorer]\r\n${printObject(adapter.explorerInfo)}\r\n`
        + `[Device:Camera]\r\npermission: ${mediaDevice.cameraPerm}\r\ncameras:\r\n${printObject(mediaDevice.cameras)}\r\n`
        + `[Device:Microphone]\r\npermission: ${mediaDevice.microphonePerm}\r\nmicrophones:\r\n${printObject(mediaDevice.microphones)}\r\n`
        + `[Device:Loudspeaker]\r\npermission: ${mediaDevice.loudspeakerPerm}\r\nloudspeakers:\r\n${printObject(mediaDevice.loudspeakers)}\r\n`

    return {
        name: name,
        info: info,
    }
}