import CryptoJS from 'crypto-js'
import moment from "moment";
import { config } from '../../util/version';
import { Log } from '../../util/log';

class Line {
    constructor(level, content) {
        this.__level = level
        this.__content = content
    }

    get level() {
        return this.__level;
    }

    get content() {
        return this.__content;
    }
}

class File {
    constructor(name, info, userData, suffix = '.log') {
        this.__id = this.__timestamp = Date.now();
        this.__name = name;
        this.__info = info;
        this.__userData = userData;
        this.__suffix = suffix;

        this.__lines = [];
    }

    get id() {
        return this.__id;
    }

    set name(value) {
        this.__name = value;
    }

    get name() {
        return this.__name;
    }

    set info(value) {
        this.__info = value;
    }

    get info() {
        return this.__info;
    }

    set userData(value) {
        this.__userData = value;
    }

    get userData () {
        return this.__userData;
    }

    set suffix(value) {
        this.__suffix = value;
    }

    get suffix () {
        return this.__suffix;
    }

    get lines() {
        return this.__lines;
    }

    getLineCnt() {
        return this.__lines.length();
    }

    addLine(level) {
        let c = [...arguments].splice(1).join(' ')
        this.__lines.push(new Line(level, c))
    }

    packet() {
        return {
            name: `${this.__name}_${moment(this.__timestamp).format("YYYY_MM_DD_HH_mm_ss")}.${this.__suffix}`,
            content: `${this.__info}\r\n[LOG]\r\n${this.__lines.map(line => line.content).join("\r\n")}`,
        }
    }
}

export class Uploader {
    constructor() {
        this.__genSignature();
        setTimeout(() => {
            this.__init();
        }, 2000);

        this.__files = {};
    }

    __genSignature() {
        this.__policyBase64 = btoa(JSON.stringify(config.upload.policy))
        let bytes = CryptoJS.HmacSHA1(this.__policyBase64, config.upload.accessKey, { asBytes: true });
        this.__signature = CryptoJS.enc.Base64.stringify(bytes)
    }

    __setUploadParam(pl, filename) {
        let key = '';
        if (filename !== '') {
            key = filename
        }

        let dir = config.upload.dir
        if (dir && dir.length > 0) {
            if (!dir.endsWith('/')) {
                dir += '/'
            }
        } else {
            dir = "";
        }
    
        dir += `${moment().format("YYYY-MM-DD")}/`
    
        if (pl) {
            pl.setOption({
                'url': config.upload.host,
                'multipart_params': {
                    'key' : dir + key,
                    'policy': this.__policyBase64,
                    'OSSAccessKeyId': config.upload.accessId, 
                    'success_action_status': '200', //让服务端返回200,不然，默认会返回204
                    'signature': this.__signature,
                }
            });
    
            pl.start()
        }
    }

    __init() {
        let that = this;
        let pl = new window.plupload.Uploader({
            url: config.upload.host,
            runtimes: 'html5,silverlight,html4',
            browse_button: document.getElementById('dom_file_select'), 
            flash_swf_url: 'libs/plupload-2.3.6/js/Moxie.swf', 
            silverlight_xap_url: 'libs/plupload-2.3.6/js/Moxie.xap', 
        });
        this.__pl = pl;
        
        pl.disableBrowse(true);
        pl.bind("PostInit", function () {
            // console.log("upload post init")
            document.getElementById('dom_file_submit').onclick = function(e) {
                that.__setUploadParam(pl, '');
            }
        });
        pl.bind("FilesAdded", function (pl, files) {
            // console.log("upload files added", files)
        });
        pl.bind("BeforeUpload", function (pl, file) {
            // console.log("before upload", file)
            that.__setUploadParam(pl, file.name)
        });
        pl.bind("UploadProgress", function (pl, file) {
            // console.log("upload progress", file)
        });
        pl.bind("FileUploaded", function (pl, file, info) {
            // console.log("file uploaded", file, info)
            if (that.__uploadSuccess) {
                that.__uploadSuccess(file, info)
            }
        });
        pl.bind("Error", function (pl, err) {
            console.warn("upload error", err)
            if (that.__uploadError) {
                that.__uploadError(err)
            }
        });
        pl.init();

        // let blob = new Blob([], {type: "text/plain"});
        // let f = new window.moxie.file.File([blob], "123", {type: "text/plain"});
        // f.name = "123.txt";
        // pl.addFile(f);

        // console.log(pl);
    }

    addFile(name, info, userData, suffix) {
        let file = new File(name, info, userData, suffix)
        this.__files[file.id] = file
        return file;
    }

    upload(file = null, success = undefined, error = undefined) {
        let that = this;
        function add(file) {
            let { name, content } = file.packet();
            let blob = new Blob([], {type: "text/plain"});
            try {
                let f = new window.moxie.file.File(blob, content, {type: "text/plain"});
                f.name = name;
                if (that.__pl) {
                    that.__pl.addFile(f);
                }
            } catch (error) {
                Log.error(`upload catch error: ${error.toString()}`)
            }
            
        }

        if (!file) {
            for (let _id in this.__files) {
                let file = this.__files[_id];
                add(file)
            }
        } else {
            add(file)
        }

        this.__files = {}
        this.__setUploadParam(that.__pl, '');

        this.__uploadSuccess = success;
        this.__uploadError = error;
    }
}