
import { netRequestThunk2 } from '../util/netReqRD';

export const ACT_NET_LOGIN = 'login';
export const ACT_NET_USER_INFO = 'userInfo';

export const apiLogin = (props, req, successFun = undefined, errorFun = undefined) => {
    console.log("apiLogin...");
    const {dispatch, } = props;
    const path = '/cs/v1/app/user/get/token'
    dispatch(netRequestThunk2(path, req, {}, ACT_NET_LOGIN, successFun, errorFun));
}

export const apiUser = (props, token, successFun = undefined, errorFun = undefined) => {
    console.log("apiUser...");
    const { dispatch } = props;
    const path = '/cs/v1/app/user/get/userInfo'
    let header = {
        Token: token
    }
    dispatch(netRequestThunk2(path, {}, header, ACT_NET_USER_INFO, successFun, errorFun));
}