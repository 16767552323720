import moment from "moment";
import { Log } from "../../util/log";

export let Status = {
    INIT: 'init',
    DISCONNECTED: 'disconnected',
    CONNECTING: 'connecting',
    RECONNECTING: 'reconnecting',
    CONNECTED: 'connected',
}

export class Mqtt {

    TOPIC_CONFERENCE_PREFIX = "cs/app/notify/conference";
    TOPIC_MEMBER_PREFIX = "cs/app/notify/member";

    constructor() {
        this.__conferenceId = 0;
        this.__sipNum = "";
        this.__mqttInfo = {};

        this.__client = null;
        this.__topic = {};
        this.__status = Status.INIT;

        this.__onConnect = null;
        this.__onSubscribe = null;
        this.__onMessage = null;
        this.__onClose = null;
    }

    get topic() {
        return this.__topic;
    }

    get client() {
        return this.__client;
    }

    get status() {
        return this.__status;
    }

    on(type, fun) {
        switch(type) {
            case "connect": {
                this.__onConnect = fun;
                break;
            }
            case "subscribe": {
                this.__onSubscribe = fun;
                break;
            }
            case "message" : {
                this.__onMessage = fun;
                break;
            }
            case "close" : {
                this.__onClose = fun;
                break;
            }
            default:
                break;
        }
    }

    stop() {
        this.__status = Status.INIT;
        if (this.__client) {
           this.__client.end()
        }
    }

    start(conferenceId, sipNum, mqttInfo) {
        let mqtt = require("mqtt")
        let client = undefined;
        this.__status = Status.CONNECTING;
        try {
            client = mqtt.connect("", {
                host: mqttInfo.ServerWssHost,
                port: mqttInfo.ServerWssPort,
                username: mqttInfo.Accout,
                password: mqttInfo.Password,
                // protocolVersion: 4,
                clientId: `${mqttInfo.ClientId}_${moment().unix()}`,
                keepalive: 30,
                protocol: "wss",
            })
        } catch (error) {
            Log.error(`catch: ${error}`);
        }

        if (!client) {
            return undefined;
        }

        let topicConference = `${this.TOPIC_CONFERENCE_PREFIX}/${conferenceId}`;
        let topicMember = `${this.TOPIC_MEMBER_PREFIX}/${sipNum}`;

        // 断开连接
        client.on("close", () => {
            Log.info(`mqtt closed. connected: ${client.connected} reconnecting: ${client.reconnecting}`)
            if (client.reconnecting) {
                this.__status = Status.RECONNECTING;
            } 
            if (this.__onClose) {
                this.__onClose()
            }
        });

        // 发生错误
        client.on('error', (err) => {
            Log.error("mqtt error: " + err)
            if (this.__onError) {
                this.__onError(err)
            }
        });

        // 收到消息
        client.on("message", (topic, msg) => {
            if (this.__onMessage) {
                if (this.__onMessage(topic, msg)) {
                    return;
                }
            }
        });

        let onSubscribe = (err, classfiy, topic) => {
            if (this.__onSubscribe) {
                if (this.__onSubscribe(err, classfiy, topic)) {
                    return;
                }
            }
            if (!err) {
                Log.info(`mqtt subscribe success. topic: ${topic}`);
            } else {
                Log.error(`mqtt subscribe failed. topic: ${topic}`);
            }
        }

        // 连接成功
        client.on("connect", () => {
            this.__status = Status.CONNECTED;
            Log.info(`mqtt connected. url: wss://${mqttInfo.ServerWssHost}:${mqttInfo.ServerWssPort}`)
            if (this.__onConnect) {
                if (this.__onConnect()) {
                    return;
                }
            }
            client.subscribe(topicConference, (err) => onSubscribe(err, 'conference', topicConference))
            client.subscribe(topicMember, (err) => onSubscribe(err, 'member', topicMember))
        })
        

        this.__client = client;
        this.__topic = {
            conference: topicConference,
            member: topicMember,
        };
        this.__conferenceId = conferenceId;
        this.__sipNum = sipNum;
        this.__mqttInfo = mqttInfo;

        return {
            client: this.__client,
            topic: this.__topic,
        }
    }

}