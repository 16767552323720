import React, { Component } from "react";
import { connect } from "react-redux"
import { Result, Button, Space } from "antd";
import { withTranslation } from 'react-i18next'

import "./nomatch.css"

@withTranslation()
class NoMatch extends Component {
    constructor(props){
        super(props);
        
        this.state = {

        }
    }

    componentDidMount() {
        let { t } = this.props;
        this.setState({
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
            reason: t('nomatch.reason.not_find'),
        });
        window.addEventListener("resize", () => {
            this.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            })
        });
    }

    render() {
        let { reason, height, width } = this.state;
        let { t } = this.props;

        return <div className="not-find-wapper" style={{height: height, width: width}}>
            <Result
                className="not-find"
                status="404"
                title="404"
                subTitle={reason}
                extra={<Space>
                    <Button type="primary" onClick={(e) => {
                        window.location.href = window.location.protocol + "//" + window.location.host
                    }}>{t('nomatch.btn.back_home')}</Button>
                    <Button type="primary" danger onClick={(e) => {
                        window.open('','_parent','');  
                        window.close();  
                    }}>{t('nomatch.btn.close')}</Button>
                </Space>}
            />
        </div>
    }
}

export default connect(
    null, 
    null
)(NoMatch);