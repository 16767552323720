// 布局模式
export var LayoutMode = {
    OVERLAP: 1, // 画中画(浮动)
    SPLIT_4: 2, // 四分屏
    SPLIT_9: 3, // 九分屏
}

// 分辨率
export var Definition = {
    HIGH: 4, // 高清 1280*720
    STANDARD: 3, // 标清 960*540
    FLUID: 2, // 流畅 640*360
    LOW: 1, // 低清 320*180
}

/**
 * 选流时的用户View类
 * @class View
 * @constructor
 */
export class View {
    /**
     * 构造函数
     * @method constructor
     * @for View
     * @param {String} sipNum SIP号码
     * @param {Number} streamId 流ID
     * @param {Definition} definition 分辨率
     * @param {String} nickName 昵称 
     * @return {View} 实例对象
     */
    constructor(sipNum, streamId, definition, nickName) {
        this.__sipNum = sipNum;
        this.__streamId = streamId;
        this.__definition = definition;
        this.__nickName = nickName;
    }
    get definition() {
        return this.__definition;
    }
    /**
     * 打包选流时的用户View信息，供接口使用
     * @method package
     * @for View
     * @return {Map} 打包好的用户View信息
     */
    package() {
        return {
            SipNum: this.__sipNum,
            StreamId: this.__streamId,
            Definition: this.__definition,
        };
    }
    /**
     * 比较streamId是否一致
     * @method cmpStreamId
     * @for View
     * @param {View} other 另外一个View
     * @return {Boolean} 比较本View和其他View的流ID是否一致
     */
    cmpStreamId(other) {
        return this.__streamId === other.__streamId;
    }
    /**
     * 获取View的昵称
     * @method getNickName
     * @for View
     * @return {String} View的昵称
     */
    getNickName() {
        return this.__nickName;
    }
}

export class Views {
    constructor(layoutMode) {
        this.__views = [];
        this.__layoutMode = layoutMode;
    }
    /**
     * 设置布局模式
     * @method setLayoutMode
     * @for Views
     * @param {LayoutMode} layoutMode 布局模式
     * @return {Void} 无
     */
    setLayoutMode(layoutMode) {
        this.__layoutMode = layoutMode;
    }
    /**
     * 获取View list的长度
     * @method length
     * @for Views
     * @return {Number} View list的长度
     */
    length() {
        return this.__views.length;
    }
    /**
     * 获取View list是否为空
     * @method isEmpty
     * @for Views
     * @return {Boolean} View list为空则返回true，否则返回false
     */
    isEmpty() {
        return !this.length();
    }
    /**
     * 在View list中找到满足streamId条件的View
     * @method findByStreamId
     * @for Views
     * @param {Number} streamId 流ID
     * @return {View} 找到了返回那个View，没找到返回undefined
     */
    findByStreamId(streamId) {
        return this.__views.find((v) => v.__streamId === streamId);
    }
    /**
     * 在View list中找到满足sipNum条件的View
     * @method findBySipNum
     * @for Views
     * @param {String} sipNum sip号码
     * @return {View} 找到了返回那个View，没找到返回undefined
     */
    findBySipNum(sipNum) {
        return this.__views.find((v) => v.__sipNum === sipNum);
    }
    /**
     * 在View list中移除满足streamId条件的View
     * @method removeByStreamId
     * @for Views
     * @param {Number} streamId 流ID
     * @return {View} 移除成功被移出的View，没有变化返回undefined
     */
    removeByStreamId(streamId) {
        let view = undefined;
        this.__views.forEach((v, index, arr) => {
            if (v.__streamId === streamId) {
                view = v;
                arr.splice(index, 1);
            }
        });
        return view;
    }
    /**
     * 在View list中移除满足sipNum条件的View
     * @method removeBySipNum
     * @for Views
     * @param {String} sipNum 流ID
     * @return {View} 移除成功被移出的View，没有变化返回undefined
     */
    removeBySipNum(sipNum) {
        let view = undefined;
        this.__views.forEach((v, index, arr) => {
            if (v.__sipNum === sipNum) {
                view = v;
                arr.splice(index, 1);
            }
        });

        console.log("remove view by sipNum: " + sipNum + " view: " + view);
        return view;
    }
    /**
     * 在View list中移除所有View
     * @method removeAll
     * @for Views
     * @return {Boolean} 移除成功返回true，没有变化返回false
     */
    removeAll() {
        let oldLen = this.length();
        this.__views = [];
        return oldLen !== 0;
    }
    /**
     * 在View中设置某路流为高清
     * @method setToHight
     * @for Views
     * @return {Void} 无
     */
    setToHight(streamId) {
        if (this.__layoutMode === LayoutMode.OVERLAP) {
            this.__views.forEach((view, index) => {
                view.__definition = view.__streamId === streamId ? Definition.HIGH : Definition.LOW;
            });
        }
    }
    /**
     * 在View list中增加一个View
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.OVERLAP}：
     *  增加的view是第一个，则强制设置的分辨率为HIGH
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.SPLIT_4}或者{LayoutMode.SPLIT_9}：
     *  增加的view则需要满足最大个数的限制。LayoutMode.SPLIT_4为3个，LayoutMode.SPLIT_9为8个
     * 若streamId为null，则会自动在View list找一个空闲的streamId赋值给此view。
     * 若streamId不为null，并且streamId与现在View list中的View的streamId重复，则移除View list中的那个，再把此View新增进去
     * @method add
     * @for Views
     * @param {View} view View对象
     * @return {Boolean} 增加成功返回true，没有变化返回false
     */
    add(view) {
        let len = this.length();
        let ret = this.findBySipNum(view.__sipNum);
        let addFlag = false;
        if (ret) {
            console.oldwarn("find same sip number in views. ", view, this.__views)
            return addFlag;
        }
        else {
            console.log("add view before. ", this.__views, view)
        }
        switch (this.__layoutMode) {
            case LayoutMode.OVERLAP: {
                if (len === 0) {
                    if (view.__streamId === null) {
                        view.__streamId = 0;
                    }
                    // 第一个强制修改为高清
                    view.__definition = Definition.HIGH;
                    this.__views.push(view);
                    addFlag = true;
                }
                else {
                    if (view.__streamId === null) {
                        // 找一个空位置
                        Array.from({ length: 9 }).forEach((item, index) => {
                            // console.log("find null pos ", index, view.__streamId)
                            if (view.__streamId === null) {
                                let ret = this.findByStreamId(index);
                                if (!ret) {
                                    view.__streamId = index;
                                    this.__views.push(view);
                                    addFlag = true;
                                }
                            }
                        });
                    }
                    else {
                        this.removeByStreamId(view.__streamId);
                        this.__views.push(view);
                        addFlag = true;
                    }
                }
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                let limit = this.__layoutMode === LayoutMode.SPLIT_4 ? 4 : 9;
                if (len <= limit) {
                    if (view.__streamId === null) {
                        // 找一个空位置
                        Array.from({ length: limit }).forEach((item, index) => {
                            if (view.__streamId === null) {
                                let ret = this.findByStreamId(index);
                                if (!ret) {
                                    view.__streamId = index;
                                    this.__views.push(view);
                                    addFlag = true;
                                }
                            }
                        });
                    }
                    else {
                        this.removeByStreamId(view.__streamId);
                        this.__views.push(view);
                        addFlag = true;
                    }
                }
                break;
            }
            default: {
                throw TypeError("not supported layout mode: ", this.__layoutMode);
            }
        }
        console.log("add view after. ", this.__views);
        return addFlag;
    }
    /**
     * 打包View为api要求的格式
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.OVERLAP}：
     * 则只会有一路View的 definition为 @link {Definition.HIGH}，其余全低清
     * 若@link {LayoutMode} 设置为 @link {LayoutMode.SPLIT_4}或者{LayoutMode.SPLIT_9}：
     * 所有路View的definition均为@link {Definition.LOW}
     * @method package
     * @for Views
     * @return {Array} 满足api格式要求的view数组
     */
    package() {
        let rets = [];
        switch (this.__layoutMode) {
            case LayoutMode.OVERLAP: {
                this.__views.forEach((view, index) => {
                    rets.push(view.package());
                });
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                // 全低清
                this.__views.forEach((view, index) => {
                    view.__definition = Definition.LOW;
                    rets.push(view.package());
                });
                break;
            }
            default:
                break;
        }
        return rets;
    }
}










