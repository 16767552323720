import React, { Component } from "react";
import { connect } from "react-redux"
import moment from "moment";
import { Layout, Typography, Button, Row, Col, Input, Popconfirm, Drawer, Modal, Tooltip, Menu, Dropdown, List, Space, Collapse, Descriptions, } from "antd";
import { CaretRightOutlined, QuestionCircleOutlined, } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../util/iconFont";
import { getLoginData, getUserData, } from "../../redux/reducers/login"
import { apiConferenceCreate, apiConferenceInfo, apiConferenceDetail, apiConferenceScreenSet, apiConferenceBoardStart, apiConferenceBoardStop} from "../../api/conference";
import Login from "../../component/login/login";
import MemberList from "../../component/memberList/memberList";
import Timer from "../../component/timer/timer";
import Detail from "../../component/detail/detail";
import Setting from "../../component/setting/setting";
import Rtc from "../../component/rtc/rtc";
import VideoLayout from "../../component/meet/videoLayout/videoLayout";
import { Log } from "../../util/log";
import { LayoutMode, Definition, Views, View } from "../../api/structure";
import { Fsm, State as FsmState } from "../../component/fsm/fsm";
import { Stream, Kind, Dir, } from "../../component/media/stream";
import { device as mediaDevice } from "../../component/media/device";
import { adapter } from "../../component/media/adapter";
import { DialHistory } from "../../component/localStorage/dialHistory";
import { DevicePreference } from "../../component/localStorage/devicePreference";
import { Mqtt } from "../../component/mqtt/mqtt";
import { getTimeDesc, getDateDiff, getUrlParam, invalidTime, printObject, uploadInfo} from "../../util/logic";
import { version, config } from "../../util/version";
import { MenuState, BoardShareState, ScreenShareState, LiveState, BackgroudViewType, } from "../../component/common/common";
import { message } from "../../util/message";
import { sipInit, sipConfig, sipRegister, sipUnregister, sipCall, sipHangUp, sipMute, sipSwitchVideo, sipInitCallSession, sipInitRegisterSession, sipInitStack} from "../../component/sip/sip";

import "./main.css"
import "./drawer.css"

@withTranslation('translation', {withRef: true})
class Main extends Component {
    constructor(props){
        super(props);

        this.commonErrorHd = this.commonErrorHd.bind(this);

        this.deviceOnChange = this.deviceOnChange.bind(this);

        this.mqttOnConnect = this.mqttOnConnect.bind(this);
        this.mqttOnClose = this.mqttOnClose.bind(this);
        this.mqttOnMessage = this.mqttOnMessage.bind(this);

        this.mediaPreviewOnSuccess = this.mediaPreviewOnSuccess.bind(this);
        this.mediaPreviewOnError = this.mediaPreviewOnError.bind(this);

        this.sipInit = sipInit.bind(this);
        this.sipConfig = sipConfig.bind(this);
        this.sipRegister = sipRegister.bind(this);
        this.sipUnregister = sipUnregister.bind(this);
        this.sipCall = sipCall.bind(this);
        this.sipHangUp = sipHangUp.bind(this);
        this.sipMute = sipMute.bind(this);
        this.sipSwitchVideo = sipSwitchVideo.bind(this);
        
        this.meetJoinOnClick = this.meetJoinOnClick.bind(this);
        this.meetCreateAndJoinOnClick = this.meetCreateAndJoinOnClick.bind(this);
        this.meetCopyOnClick = this.meetCopyOnClick.bind(this);
        this.meetBarVisibleOnClick = this.meetBarVisibleOnClick.bind(this);
        
        this.videoZoomInOnClick = this.videoZoomInOnClick.bind(this);
        this.videoSwitchOnClick = this.videoSwitchOnClick.bind(this);
        this.videoDivOnMouseMove = this.videoDivOnMouseMove.bind(this);

        this.menuIconToggleOnClick = this.menuIconToggleOnClick.bind(this);
        this.menuItemOnClick = this.menuItemOnClick.bind(this);
        this.menuItemBoardShareOnClick = this.menuItemBoardShareOnClick.bind(this);
        this.menuItemScreenShareOnClick = this.menuItemScreenShareOnClick.bind(this);
        
        // 拨号历史
        let dialHistory = new DialHistory();

        // 媒体处理
        let stream = new Stream();
        stream.preview.onGetMediaSuccess = this.mediaPreviewOnSuccess;
        stream.preview.onGetMediaError = this.mediaPreviewOnError;

        // mqtt
        let mqtt = new Mqtt();
        mqtt.on('connect', this.mqttOnConnect);
        mqtt.on('close', this.mqttOnClose);

        this.state = {
            flag: true,
            fsm: new Fsm(),
            stream: stream,
            mqtt: mqtt,

            dialHistory: dialHistory,
            devicePreference: new DevicePreference(),
            // url中携带会议号码，登录时直接进入会议
            conferenceNum: getUrlParam("conference", this.props.location.search),

            ...this.initModal(),

            permission: true, // 权限是否已经获取

            avInfoInterval: 5, // 单位（秒）
            barVisibleInterval: 10, //单位（秒）

            code: dialHistory.getFirst('code'), // 当前拨号的号码
            
            sipStackStatus: 'disconnected',

            ...this.initCallSession(),
        };
    }

    initModal() {
        return {
            conferenceVisible: false,
            createVisible: false,
            joinVisible: false,
            barVisible: true,
            memberVisible: false,
            detailVisible: false,
            rtcInfoVisible: false,
            settingVisible: false,
            permissionHelpVisible: false,
        }
    }

    initCallSession() {
        return { 

            ...sipInitCallSession(),

            // 按钮
            videoEnable: true,
            audioEnable: true,

            localEnable: true,
            boardEnable: false, 
            remotesEnable: false,

            // 布局模式
            layoutMode: LayoutMode.OVERLAP,

            // 浮动模式下底是白板还是会场画面
            backgroudViewType: BackgroudViewType.VIDEO,
            backgroudViewIndex: 0,

            // 菜单锁定
            menuLock: MenuState.UNLOCK,

            // 白板共享
            boardShare: BoardShareState.STOP,

            // 屏幕共享
            screenShare: ScreenShareState.STOP,
            
            // 直播状态
            liveState: LiveState.STOP,

            // 会议主题
            theme: undefined,

            // 会议接入码（这里不置为undefined，保留最后一次呼叫的接入码）
            // code: undefined,

            // 会议SIP号
            number: undefined,

            // 会议详情
            detail: undefined,

            // 已存在的会议
            exist: undefined,

            // 创建者
            creator: undefined,

            // 选流信息
            views: new Views(LayoutMode.OVERLAP),

            // ice状态
            iceStatus: undefined,
            iceDescription: undefined,

            // RTC报告
            asReports: undefined,
            arReports: undefined, 
            vsReports: undefined, 
            vrReports: undefined,

            // mqtt状态
            mqttStatus: 'init',

        }
    }

    // 退出逻辑
    exit(isUnregister = true, immediate = false, toast = null) {
        let that = this;
        if (isUnregister) {
            this.sipUnregister();
        }
        
        let exitFun = () => {
            let { stream, fsm } = that.state;
            this.exitTimeout = null;
           
            if (stream) {
                stream.stop(Kind.ALL, Dir.ALL);
                stream.preview.stop();
            }
            if (fsm) {
                fsm.transfer(FsmState.Login.INIT);
            }
            if (!toast) {
                message.destroy();
            }
            that.setState({
                ...sipInitStack(),
                ...sipInitRegisterSession(),
                ...that.initCallSession(),
                ...that.initModal(),
            })
        }

        if (this.exitTimeout) {
            clearTimeout(this.exitTimeout);
            this.exitTimeout = null;
        }
        if (immediate) {
            exitFun();
        } else {
            this.exitTimeout = setTimeout(() => {exitFun();}, 1000);
        }
    }

    viewInit(isStartMqtt = false, sessionId = 0, views = null) {
        let that = this;
        let { code, mqtt, dialHistory } = this.state;
        let { user } = this.props;
        apiConferenceInfo(this.props, code, (dispatch, rsp1, req) => {
            let memberFilter = {
                pageNum: 1,
                pageSize: 50,
                state: "joined",
            }
            if (isStartMqtt) {
                mqtt.on("message", (topic, msg) => this.mqttOnMessage(topic, msg));
                mqtt.start(rsp1.ConferenceId, user.SipInfo.SipNum, user.MqttInfo);
            }
            apiConferenceDetail(this.props, rsp1, memberFilter, (dispatch, rsp2, req) => {
                // console.info("conference", rsp)
                // 记录会议详情信息
                let liveState = LiveState.STOP;
                if (rsp2.PusherInfo && !invalidTime(rsp2.PusherInfo.StartTime) && invalidTime(rsp2.PusherInfo.EndTime)) {
                    liveState = LiveState.START;
                }
                that.setState({
                    detail: rsp2,
                    boardShare: rsp2.BoardUrlHttps ? BoardShareState.START : BoardShareState.STOP,
                    liveState: liveState,
                })
                if (sessionId) {
                    let content = {
                        theme: rsp2.Title,
                        creator: rsp1.CreateUserName,
                    }
                    dialHistory.mod(sessionId, content)
                }

                let { layoutMode, backgroudViewType } = that.state;
                views.removeAll();
                if (rsp2.MemberList && rsp2.MemberList.length > 1) {
                    // 选不是自己的流
                    rsp2.MemberList.forEach((member) => {
                        if (member.SipNum !== that.props.user.SipInfo.SipNum) {
                            views.add(new View(member.SipNum, null, Definition.LOW, member.NickName));
                        }
                    })
                    if (layoutMode === LayoutMode.OVERLAP && backgroudViewType === BackgroudViewType.VIDEO) {
                        that.setState({
                            backgroudViewIndex: 0,
                            remotesEnable: views.length() === 1 ? false : that.state.remotesEnable,
                        })
                    }
                    that.viewSelect(rsp2, views);
                } else {
                    // 会场里只有自己，则清除选流
                    that.setState({
                        viewChangeFlag: !that.state.viewChangeFlag,
                        views: views,
                        remotesEnable: false,
                    })
                }
            }, that.commonErrorHd)
        }, that.commonErrorHd)
    }

    viewSelect(detail, views) {
        let that = this;
        let { t } = this.props;
        Log.info(`select view to member: ${views}`)
        apiConferenceScreenSet(this.props, detail, views.package(), (dispatch, rsp3, req) => {
            // 记录会议选流信息
            if (!rsp3.SuccessViews) {
                Log.error(`select view failed. rsp: ${rsp3}`)
                message.error(t('view.message.api.screen_set.error'), 5);
            } else {
                that.setState({
                    viewChangeFlag: !that.state.viewChangeFlag,
                    views: views,
                })
            }
            that.state.stream.reattachAllVideoRecv();
        }, that.commonErrorHd)
    }

    conferenceAddr(detail) {
        return window.location.protocol + "//" + window.location.host + window.location.pathname + "?conference=" + (detail ? detail.ConferenceCode : "");
    }

    liveAddr(detail) {
        return window.location.protocol + "//" + window.location.host + "/vlive?conference=" + (detail ? detail.ConferenceCode : "");
    }

    deviceOnChange(event) {
        this.setState({
            flag: this.state.flag,
        })
    }

    commonErrorHd(dispatch, rsp, req) {
        Log.error(`error: ${rsp}`)
        return true;
    }

    // MQTT连接成功
    mqttOnConnect() {
        let { mqtt } = this.state;
        this.setState({
            mqttStatus: mqtt.status,
        })
        return 0;
    }

    // MQTT连接断开
    mqttOnClose() {
        let { mqtt } = this.state;
        this.setState({
            mqttStatus: mqtt.status,
        })
        return 0;
    }

    // MQTT消息处理
    mqttOnMessage(topic, msg) {
        let that = this;
        let { mqtt, stream } = this.state;
        let { t, user } = this.props;
        // message is Buffer
        let content = {};
        try {
            content = JSON.parse(msg.toString()) || {};
        } catch (error) {
            Log.error(`${error}`);
            return;
        }
        Log.info(`mqtt message: ${content}`)
        if (topic === mqtt.topic.conference) {
            switch(content.MsgCode) {
                case 1000: {// 会议创建
                    break;
                }
                case 1001: {// 会议释放
                    break; 
                }
                case 1002: {// 成员入会
                    let that = this;
                    let { detail, views } = this.state;
                    if (content.MemberInfo.SipNum === user.SipInfo.SipNum) {
                        // 入会是自己，不处理
                        break;
                    }
                    
                    message.info(t('member.message.notify.join', {member: content.MemberInfo.NickName}))
                    if (this.memberListDOM) {
                        this.memberListDOM.reloadData();
                    }

                    // 重新刷新会场信息
                    let memberFilter = {
                        pageNum: 1,
                        pageSize: 50,
                        state: "joined",
                    }
                    apiConferenceDetail(this.props, detail, memberFilter, (dispatch, rsp2, req) => {
                        that.setState({
                            detail: rsp2,
                        });
                    }, that.commonErrorHd)
    
                    let changed = views.add(new View(content.MemberInfo.SipNum, null, Definition.LOW, content.MemberInfo.NickName))
                    if (changed) {
                        // 有变化，则选流
                        apiConferenceScreenSet(this.props, detail, views.package(), (dispatch, rsp3, req) => {
                            that.setState({
                                viewChangeFlag: !that.state.viewChangeFlag,
                                views: views,
                            })
                        }, this.commonErrorHd)
                    }
                    break;
                }
                case 1003: {// 成员离会
                    let that = this;
                    let { detail, views } = this.state;
    
                    if (content.MemberInfo.SipNum === user.SipInfo.SipNum) {
                        // 离会是自己，不处理
                        break;
                    }
    
                    message.info(t('member.message.notify.leave', {member: content.MemberInfo.NickName}))
                    if (this.memberListDOM) {
                        this.memberListDOM.reloadData();
                    }

                    // 重新刷新会场信息
                    let memberFilter = {
                        pageNum: 1,
                        pageSize: 50,
                        state: "joined",
                    }
                    apiConferenceDetail(this.props, detail, memberFilter, (dispatch, rsp2, req) => {
                        that.setState({
                            detail: rsp2,
                        });
                    }, that.commonErrorHd)
    
                    // 如果当前预览中有该成员，则重新选流（防抖）
                    if (this.reselectTimeout) {
                        clearTimeout(this.reselectTimeout);
                    }
                    this.reselectTimeout = setTimeout(() => {
                        let view = views.removeBySipNum(content.MemberInfo.SipNum)
                        if (view) {
                            Log.info("reselect view")
                            this.viewInit(false, 0, views);
                        } 
                    }, 1000)
                    break;
                }
                case 1100: {// 白板开始共享
                    let { detail } = this.state;
                    let { user } = this.props;
                    
                    if (user.SipInfo.SipNum === content.BoardInfo.BoardOwner) {
                        message.info(t('board.message.notify.start.success'))
                        
                        if (this.boardStartTimeout) {
                            // 先收到200OK，再收到mqtt的通知
                            clearTimeout(this.boardStartTimeout);
                            this.boardStartTimeout = null;
                            this.boardStartNotify = false;
                        } else {
                            // 先收到mqtt的通知，再收到200OK
                            this.boardStartNotify = true;
                        }
                    } else {
                        message.info(t('board.message.notify.start.doing'))
                    }
                    
                    detail.BoardUrlHttps = content.BoardInfo.BoardUrlHttps;
                    detail.BoardOwner = content.BoardInfo.BoardOwner;
    
                    that.setState({
                        detail: detail,
                        boardShare: BoardShareState.START,
                        boardEnable: true,
                    })
                    break;
                }
                case 1101: {// 白板结束共享
                    let { detail } = this.state;
                    let { user } = this.props;
                    
                    if (user.SipInfo.SipNum === content.BoardInfo.BoardOwner) {
                        message.info(t('board.message.notify.stop.success'))
                        if (this.boardStopTimeout) {
                            // 先收到200OK，再收到mqtt的通知
                            clearTimeout(this.boardStopTimeout);
                            this.boardStopTimeout = null;
                            this.boardStopNotify = false;
                        } else {
                            // 先收到mqtt的通知，再收到200OK
                            this.boardStopNotify = true;
                        }
                    } else {
                        message.info(t('board.message.notify.stop.end'))
                    }

                    stream.reattachVideoRecvById(0);
                    detail.BoardUrlHttps = undefined;
                    detail.BoardOwner = undefined;
    
                    that.setState({
                        detail: detail,
                        boardShare: BoardShareState.STOP,
                        boardEnable: false,
                        backgroudViewType: BackgroudViewType.VIDEO,
                    })
                    break;
                }
                case 1201: { // 直播开始
                    message.info(t('live.message.notify.start'))
    
                    that.setState({
                        liveState: LiveState.START,
                    })
                    break;
                }
                case 1202: { // 直播结束
                    message.info(t('live.message.notify.stop'))
    
                    that.setState({
                        liveState: LiveState.STOP,
                    })
                    break;
                }
                default:
                    Log.error("unsupported msg code: " + content.MsgCode)
                    break;
    
            }   
        } else if (topic === mqtt.topic.member) {
            switch(content.MsgCode) {
                case 2000: {// 成员入会
                    break;
                }
                case 2001: {// 成员禁音
                    break; 
                }
                case 2002: {// 成员解除禁音
                    break;
                }
                case 2003: {// 锁定画面布局
                    break;
                }
                case 2004: {// 取消锁定画面布局
                    break;
                }
                default:
                    Log.error("unsupported msg code: " + content.MsgCode)
                    break;
            }  
        }
    }

    // 打开本地预览成功
    mediaPreviewOnSuccess(stream) {
        this.setState({
            permission: true,
        })
    }

    // 打开本地预览失败
    mediaPreviewOnError(error) {
        let { t } = this.props;
        console.log(error);
        message.error(t('preview.message.error'));
        this.setState({
            permission: false,
        })
    }
    
    // 菜单栏自动隐藏功能开启
    timerBarVisibleStart() {

        this.timerBarVisibleStop();
        Log.info("bar visible timer start.");
        let { barVisibleInterval } = this.state;
        this.barTimeout = setInterval(() => {
            // 鼠标没动静则把bar隐藏起来
            let { menuLock } = this.state;
            if (!this.mouseCurX && !this.mouseCurY){
                if (menuLock === MenuState.UNLOCK) {
                    this.setState({
                        barVisible: false,
                    })
                }
            } else {
                this.mouseCurX = undefined;
                this.mouseCurY = undefined;
            }
        }, barVisibleInterval * 1000)
    }

    // 菜单栏自动隐藏功能停止
    timerBarVisibleStop() {
        if (this.barTimeout) {
            Log.info("bar visible timer stop.");
            clearTimeout(this.barTimeout);
            this.barTimeout = null;
        }
    }
    
    // 分享被点击
    meetCopyOnClick(e, param, content = "") {
        e.stopPropagation();
        let { t } = this.props;
        let input = document.getElementById("dom_copy");
        if (input) {
            let title = undefined;
            switch(param){
                case "conference": {
                    title = t('share.message.copy.conference.addr');
                    break;
                }
                case "live": {
                    title = t('share.message.copy.live.addr');
                    break;
                }
                default: {
                    title = t('share.message.copy.success');
                    break;
                }
            }
           
            input.setAttribute("value", content);
            // input.setSelectionRange(0, 9999);
            input.select();
            if (document.execCommand("copy")) {
                document.execCommand("copy")
                message.success(title);
            } else {
                message.error(t('share.message.copy.error'));
                Log.error("copy error for " + param);
            }
        }
    }

    // 加入会议被点击
    meetJoinOnClick(e, code = undefined) {
        e.stopPropagation();

        Log.info(`ui event: join onclick. conference code:${code}`);

        let {t} = this.props;
        if (!code) {
            code = this.state.code;
        } 
        if (!code || code.length <= 0) {
            message.error(t('call.message.input.code.empty'));
            Log.warn(`the conference code is empty, we can't join the conference`);
        } else {
            message.loading(t('call.message.status.doing'))
            apiConferenceInfo(this.props, code, (dispatch, rsp, req) => {
                if (rsp.State === "inprogress") {
                    this.sipCall("call-audiovideo", rsp.SipCode, rsp.ConferenceCode);
                    this.setState({
                        joinVisible: false,
                    })
                } else if (rsp.State === "finished") {
                    message.warn(t('call.message.api.info.status.ended', {conference: code}))
                } else if (rsp.State === "tostart"){
                    message.warn(t('call.message.api.info.status.tostart', {conference: code}))
                }
                
            }, (dispatch, rsp, req) => {
                switch(rsp.Status) {
                    case 51000:
                        message.error(t('call.message.api.info.status.not_exist'))
                        break;
                    default:
                        message.error(t('call.message.api.info.error'))
                        break;
                }
                return true;
            })
        }
    }

    // 创建并加入被点击
    meetCreateAndJoinOnClick(e) {
        e.stopPropagation();

        let {title} = this.state;
        let {t} = this.props;
        if (!title || title.length <= 0) {
            message.error(t('call.message.input.theme.empty'));
        } else {
            apiConferenceCreate(this.props, title, (dispatch, rsp, req) => {
                this.setState({
                    createVisible: false,
                    code: rsp.ConferenceCode,
                    number: rsp.SipCode,
                })
                this.sipCall("call-audiovideo", rsp.SipCode, rsp.ConferenceCode);
            }, (dispatch, rsp, req) => {
                message.error(t('call.message.api.create.error'));
                Log.error(`error: ${rsp}`)
            })
        }
    }

    // 隐藏菜单栏被点击
    meetBarVisibleOnClick(e, param) {
        e.stopPropagation();

        let { menuLock, barVisible, conferenceVisible, createVisible, joinVisible, 
            memberVisible, detailVisible, rtcInfoVisible, settingVisible, permissionHelpVisible } = this.state;
        let {t} = this.props;
        
        if (menuLock === MenuState.LOCK) {
            // 被锁住，不切换菜单显隐
            if (param === 'icon') {
                message.info(t('operation.message.bar_visible.lock'));
            }
        } else if (conferenceVisible || createVisible || joinVisible || memberVisible 
            || detailVisible || rtcInfoVisible || settingVisible || permissionHelpVisible) {
            // 目前有对话框出现，不切换菜单显隐
        }else {
            if (this.barClickTimeout) {
                clearTimeout(this.barClickTimeout);
            }
            this.barClickTimeout = setTimeout(() => {                
                this.setState({
                    barVisible: !barVisible,
                })
                this.barClickTimeout = null;
            }, 250);
        }
    }

    // 放大被点击
    videoZoomInOnClick(e, param) {

        Log.info(`video zoom in. ${param}`)
        e.stopPropagation();
        let that = this;
        let { layoutMode, detail, views, stream} = this.state;

        switch(param){
            case "remote0":
            case "remote1":
            case "remote2":
            case "remote3":
            case "remote4":
            case "remote5":
            case "remote6":
            case "remote7": {
                let index = parseInt(param.substr(6,1));
                // 小窗口点大
                if (layoutMode === LayoutMode.OVERLAP) {
                    views.setToHight(index);
                } 

                views.setLayoutMode(LayoutMode.OVERLAP);
                apiConferenceScreenSet(this.props, detail, views.package(), (dispatch, rsp3, req) => {
                    that.setState({
                        viewChangeFlag: !that.state.viewChangeFlag,
                        views: views,
                        layoutMode: LayoutMode.OVERLAP,
                        backgroudViewType: BackgroudViewType.VIDEO,
                        backgroudViewIndex: index,
                    })
                    stream.reattachAllVideoRecv();
                }, this.commonErrorHd)
                break;
            }
            case "board": {
                views.setLayoutMode(LayoutMode.OVERLAP);
                apiConferenceScreenSet(this.props, detail, views.package(), (dispatch, rsp3, req) => {
                    that.setState({
                        viewChangeFlag: !that.state.viewChangeFlag,
                        views: views,
                        layoutMode: LayoutMode.OVERLAP,
                        backgroudViewType: BackgroudViewType.BOARD,
                    })
                }, this.commonErrorHd)
                break;
            }
            case "local":
            default:
                Log.error("unspported param: " + param)
                return;
        }
    }

    // 切换被点击
    videoSwitchOnClick(e, param) {
        Log.info(`video switch. ${param}`)
        e.stopPropagation();
    }

    // 鼠标移到底层框附近出现菜单
    videoDivOnMouseMove(e) {
        let {height, width} = this.state;
        if( e.clientY < 8 || height - e.clientY < 8 
            || e.clientX < 8 || width - e.clientX  < 8){
            this.setState({
                barVisible: true,
            })
        } else {
            this.mouseCurX = e.clientX;
            this.mouseCurY = e.clientY;
        }
    }

    // 菜单栏toggle被点击
    menuIconToggleOnClick(e, type) {
        e.stopPropagation();

        let { boardShare } = this.state;
        let {t} = this.props;
        switch(type){
            case "audio": {
                let newValue = !this.state.audioEnable;
                this.setState({
                    audioEnable: newValue,
                })
                this.sipMute('audio', !newValue)
                break;
            }
            case "video": {
                let newValue = !this.state.videoEnable;
                this.setState({
                    videoEnable: newValue,
                })
                this.sipMute('video', !newValue);
                break;
            }
            case "screen": {
                let newValue = !this.state.videoEnable;
                this.setState({
                    videoEnable: newValue,
                })
                this.sipMute('screen', !newValue);
                break;
            }
            case "local":{
                this.setState({
                    localEnable: !this.state.localEnable,
                })
                break;
            }
            case "board":{
                if (boardShare === BoardShareState.START) {
                    this.setState({
                        boardEnable: !this.state.boardEnable,
                    })
                } else {
                    message.warn(t('board.message.status.not_started'));
                }
                break;
            }
            case "room":{
                this.setState({
                    remotesEnable: !this.state.remotesEnable,
                })
                break;
            }
                
            default:
                break;
        }
    }

    // 菜单栏被点击
    menuItemOnClick(e, param) {
        e.domEvent.stopPropagation();
        let state = this.state;
        let { views, boardShare, stream } = this.state;
        let {t} = this.props;
        let newValue = parseInt(e.key);
        let oldValue = state[param];
        // Log.debug(`${param} changed ${oldValue} -> ${newValue}`);
        switch(param) {
            case "backgroudViewType":{
                if (newValue !== oldValue) { 
                    if (newValue === BackgroudViewType.BOARD) {
                        if (boardShare === BoardShareState.STOP) {
                            message.warn(t('board.message.tip.not_started'));
                            return;
                        }
                    } else if (newValue === BackgroudViewType.VIDEO) {
                        if (boardShare === BoardShareState.START) {
                            state.boardEnable = true;
                        }
                    } else {
                        Log.error("unsupported key: " + newValue + "on param: " + param);
                        return;
                    }
                    stream.reattachAllVideoRecv();
                } else {
                    return;
                }

                break;
            }
            case "layoutMode": {
                views.setLayoutMode(newValue);
                if (oldValue !== newValue) {
                    // 重新选流
                    // Log.info("reselect view")
                    // this.viewInit(false, 0, views);
                    // 浮动布局和4分屏(或9分屏）互切需要重新reattch stream。4分屏切9分屏不需要重新reattch
                    if ((oldValue === LayoutMode.OVERLAP && (newValue === LayoutMode.SPLIT_4 || newValue === LayoutMode.SPLIT_9))
                        || ((oldValue === LayoutMode.SPLIT_4 || oldValue === LayoutMode.SPLIT_9) && newValue === LayoutMode.OVERLAP)) {
                        stream.reattachAllVideoRecv();
                    }
                } else {
                    return;
                }
                break;
            }
            default:
                break;
        }

        state[param] = newValue;
        this.setState({
            ...state,
        })
    }

    // 菜单栏共享白板被点击
    menuItemBoardShareOnClick(e) {
        e.domEvent.stopPropagation();

        let that = this;
        let { detail, boardShare, stream } = this.state;
        let { t, user } = this.props;
        let key = parseInt(e.key);
        switch(key) {
            case BoardShareState.START:
                if (boardShare === BoardShareState.START) {
                    if (detail.BoardOwner !== user.SipInfo.SipNum) {
                        message.warn(t('board.message.tip.has_started'));
                    } else {
                        Log.debug("board has been share by youself, do it again?")
                    }
                } else {
                    if (this.boardStartTimeout || this.boardStopTimeout) {
                        message.warn(t('board.message.tip.not_fast_click'));
                        return;
                    }
                    message.loading(t('board.message.tip.doing'));

                    apiConferenceBoardStart(this.props, detail, user.SipInfo.SipNum, (dispatch, rsp, req) => {
                        // 开启定时器等通知，没等到通知，也认为成功
                        that.boardStartTimeout = setTimeout(() => {
                            if (!that.boardStartNotify) {
                                Log.warn("board start successful but not recv notify. are you ok?")
                                message.success(t('board.message.notify.start.success'));
                                
                                detail.BoardUrlHttps = rsp.BoardUrlHttps;
                                detail.BoardOwner = user.SipInfo.SipNum;
            
                                that.setState({
                                    detail: detail,
                                    boardShare: BoardShareState.START,
                                    boardEnable: true,
                                })
                            }
                            that.boardStartNotify = false;
                            that.boardStartTimeout = null;
                        }, 3000);

                    }, (dispatch, rsp, req) => {
                        Log.error("share board start failed. rsp: " + rsp);
                        message.success(t('board.message.notify.error'));
                    });
                } 
            
                break;
            case BoardShareState.STOP:
                if (boardShare === BoardShareState.START) {
                    if (detail.BoardOwner !== user.SipInfo.SipNum) {
                        message.warn(t('board.message.tip.not_belong'));
                    } else {
                        if (this.boardStartTimeout || this.boardStopTimeout) {
                            message.warn(t('board.message.tip.not_fast_click'));
                            return;
                        }
                        message.loading(t('board.message.tip.stopping'));

                        apiConferenceBoardStop(this.props, detail, user.SipInfo.SipNum, (dispatch, rsp, req) => {
                            // 开启定时器等通知，没等到通知，也认为成功
                            that.boardStopTimeout = setTimeout(() => {
                                if (!that.boardStopNotify) {
                                    Log.warn("board stop successful but not recv notify. are you ok?")
                                    message.success(t('board.message.notify.stop.success'));
                                    
                                    detail.BoardUrlHttps = undefined;
                                    detail.BoardOwner = undefined;
                                    stream.reattachVideoRecvById(0);
                                    that.setState({
                                        detail: detail,
                                        boardShare: BoardShareState.STOP,
                                        boardEnable: false,
                                        backgroudViewType: BackgroudViewType.VIDEO,
                                    })
                                }
                                that.boardStopNotify = false;
                                that.boardStopTimeout = null;
                            }, 3000);
        
                        }, (dispatch, rsp, req) => {
                            Log.error("share board stop failed. rsp: " + rsp);
                            message.success(t('board.message.notify.stop.error'));
                        });
                    }
                } else {
                    Log.debug("board has been stopped, do it again?");
                }
                break;
            default:
                return;
        }
    }

    // 菜单栏屏幕共享被点击
    menuItemScreenShareOnClick(e) {
        e.domEvent.stopPropagation();

        let { screenShare } = this.state;
        let {t} = this.props;
        let key = parseInt(e.key);
        switch(key) {
            case ScreenShareState.START: {
                if (screenShare === ScreenShareState.STOP) {
                    message.loading(t('screen.message.status.start.doing'));
                    this.sipSwitchVideo('screen');
                }
                break;
            }

            case ScreenShareState.STOP: {
                if (screenShare === ScreenShareState.START) {
                    message.loading(t('screen.message.status.stop.doing'));
                    this.sipSwitchVideo('video');
                }
                break;
            }

            default: {
                Log.error(`unsupported key: ${key}`);
                return;
            }
        }
    }

    // 会议设置对话框
    mkConferenceSetting() {
        let { settingVisible, height, width, devicePreference } = this.state;
        let { t, login, user } = this.props;
  
        return <Modal
            className="setting"
            width={"50%"}
            title={<span className="title">
                <IconFont className="icon" type="icon-setting-dark"/>
                <span>{t('setting.info')}</span>
            </span>}
            closable={true}
            maskClosable={false}
            visible={settingVisible}
            onCancel={(e) => {this.setState({settingVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <Setting
                width={width}
                height={height}
                login={login}
                user={user}
                copyOnClick={this.meetCopyOnClick}
                cameraDeviceId={devicePreference.get('camera', mediaDevice)}
                cameras={mediaDevice.cameras}
                microphoneDeviceId={devicePreference.get('microphone', mediaDevice)}
                microphones={mediaDevice.microphones}
                loudspeakerDeviceId={devicePreference.get('loudspeaker', mediaDevice)}
                loudspeakers={mediaDevice.loudspeakers}
                onChange={(classify, deviceId) => {                        
                    let { fsm, stream } = this.state;
                    let oldDeviceId = devicePreference.get(classify, mediaDevice);
                    if (oldDeviceId !== deviceId) {
                        devicePreference.set(classify, deviceId)
                    }
                    if (fsm.test(FsmState.Call.INIT)) {
                        let config = {};
                        if (classify === "camera") {
                            config = {
                                video_input_device_id: deviceId,
                            }
                            if (oldDeviceId !== deviceId) {
                                stream.preview.start(deviceId);
                            }
                        } else if (classify === "microphone") {
                            config = {
                                audio_input_device_id: deviceId,
                            }
                        } else if (classify === "loudspeaker") {
                            config = {
                                audio_output_device_id: deviceId,
                            }
                            // if (this.videoLayout) {
                            //     let ret = this.videoLayout.setAudioRemoteDevice(deviceId);
                            //     Log.info(`set loudspeaker to ${deviceId} ${ret}`);
                            // }
                            let element = document.getElementById('dom_a_remote');
                            if (element) {
                                try {
                                    stream.attach("dom_a_remote", null);
                                    element.setSinkId(deviceId);
                                    Log.info(`set loudspeaker to ${deviceId}`);
                                } catch (error) {
                                    Log.error(`set loudspeaker to ${deviceId} error. ${error}`);
                                }
                            }
                        } else {
                            return;
                        }

                        // 更新配置
                        this.sipConfig(config)
                    } else {
                        Log.error(`status is not right, ${fsm.curState()} != ${FsmState.Call.INIT}`)
                    }
                }}
            />
        </Modal>
    }

    // 会议详情对话框
    mkConferenceDetail() {
        let { detailVisible, detail, liveState, } = this.state;
        return <Detail
            visible={detailVisible}
            detail={detail}
            living={liveState === LiveState.START}
            copyOnClick={this.meetCopyOnClick}
            onClose={(e) => {this.setState({detailVisible: false})}}
            conferenceAddrFun={this.conferenceAddr}
            liveAddrFun={this.liveAddr}
        />
    }

    // 音视频信息对话框
    mkRtcInfo() {
        let { rtcInfoVisible, asReports, arReports, vsReports, vrReports, height } = this.state;
        return <Rtc
            height={height}
            visible={rtcInfoVisible}
            asReports={asReports}
            arReports={arReports}
            vsReports={vsReports}
            vrReports={vrReports}
            onClose={(e) => {this.setState({rtcInfoVisible: false})}}
        />
    }

    // 权限帮助
    mkPermissionHelp() {
        let { permissionHelpVisible } = this.state;
        return <Modal
            className="permission-help"
            closable={false}
            width="80%"
            visible={permissionHelpVisible}
            onCancel={(e) => {this.setState({permissionHelpVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <img className="help-enable-video" alt="" src="./images/help-enable-video.png"/>
            </div>
        </Modal>
    }

    // 入会向导对话框
    mkConferenceWizzard() {
        let { conferenceVisible } = this.state;
        let {t} = this.props;

        return <Modal
            className="conference"
            width={"50%"}
            closable={false}
            visible={conferenceVisible}
            onCancel={(e) => {this.setState({conferenceVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <Typography.Title level={4}>{t('modal.conference.title')}</Typography.Title>
                <Row justify="space-between" align="middle" className="select">
                    <Col span={12} className="wapper" 
                        onClick={(e) => {
                                e.stopPropagation();
                                this.setState({conferenceVisible: false, createVisible: true});
                            }
                        }>
                        <IconFont className="icon" type="icon-startup"/>
                        <span>{t('modal.conference.create')}</span>
                    </Col>
                    <Col span={12} className="wapper" 
                        onClick={(e) => {
                                e.stopPropagation();
                                this.setState({conferenceVisible: false, joinVisible: true});
                            }
                        }>
                        <IconFont className="icon" type="icon-group"/>
                        <span>{t('modal.conference.join')}</span>
                    </Col>
                </Row>
            </div>
        </Modal>
    }

    // 创建会议对话框
    mkConferenceCreate() {
        let { createVisible, title } = this.state;
        let {t} = this.props;

        return <Modal
            className="create"
            width={"50%"}
            closable={false}
            visible={createVisible}
            onCancel={() => {this.setState({createVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title">
                    <IconFont className="icon" type="icon-startup"/>
                    <Typography.Title className="description" level={4}>{t('modal.create_conference.title')}</Typography.Title>
                </div>
                <div className="input-wapper">
                    <Input 
                        className="input" 
                        value={title} 
                        size="large" 
                        autoFocus={true} 
                        placeholder={t('modal.create_conference.new.input.placeholder')} 
                        onChange={(e) => {
                            this.setState({
                                title: e.target.value
                            })
                        }} 
                        onFocus={(e) => {
                            // 获得焦点时，自动全选内容
                            e.stopPropagation();
                            e.target.select();
                        }}
                        onKeyDown={(e) => {
                            // 敲回车则代表'确定'
                            if (e.keyCode === 13) {
                                this.meetCreateAndJoinOnClick(e)
                            }
                        }} 
                    />
                    <Button className="ok" type="primary" size="large" onClick={this.meetCreateAndJoinOnClick}>{t('modal.create_conference.new.btn.create')}</Button>
                </div>
            </div>
        </Modal>
    }

    // 加入会议对话框
    mkConferenceJoin() {
        let {joinVisible, code, dialHistory} = this.state;
        let {t} = this.props;

        return <Modal
            className="join"
            width={"50%"}
            closable={false}
            visible={joinVisible}
            onCancel={() => {this.setState({joinVisible: false})}}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                <div className="title">
                    <IconFont className="icon" type="icon-group"/>
                    <Typography.Title className="description" level={4}>{t('modal.join_conference.title')}</Typography.Title>
                </div>
                <div className="input-wapper">
                    <Input 
                        id="code"
                        className="input" 
                        value={code} 
                        size="large" 
                        autoFocus={true} 
                        placeholder={t('modal.join_conference.input.placeholder')} 
                        onChange={(e) => {
                            e.stopPropagation();
                            this.setState({
                                code: e.target.value.replace(" ",""),
                            })
                        }} 
                        onFocus={(e) => {
                            // 获得焦点时，自动全选内容
                            e.stopPropagation();
                            e.target.select();
                        }}
                        onKeyDown={(e) => {
                            e.stopPropagation();
                            // 敲回车则代表'确定'
                            if (e.keyCode === 13) {
                                this.meetJoinOnClick(e, code);
                            }
                        }} 
                    />
                    <Button className="ok" type="primary" size="large" onClick={(e) => {
                        e.stopPropagation();
                        this.meetJoinOnClick(e, code)
                    }}>
                        {t('modal.join_conference.btn')}
                    </Button>
                </div>
                <Collapse 
                    className="history-collapse"
                    bordered={false}
                    expandIcon={({ isActive }) => <CaretRightOutlined style={{color: "#1890ff"}} rotate={isActive ? 90 : 0} />}
                    defaultActiveKey={["main"]} >
                    <Collapse.Panel header={<Button type="link">{t('modal.join_conference.history.title')}</Button>} key="main">
                        <List
                            itemLayout="vertical"
                            dataSource={dialHistory.getAll()}
                            rowKey="key"
                            size="large"
                            renderItem={item => {
                                let icon = undefined;
                                let details = [];
                                let theme = t('modal.join_conference.history.theme');
                                let duration = t('modal.join_conference.history.duration');
                                let reason = t('modal.join_conference.history.reason');
                                let creator = t('modal.join_conference.history.creator');
                                let none = t('common.none')
                                let second = t('common.second')
                                if (item.status === "success") {
                                    icon = "icon-call-history-success";
                                    details.push({label: theme, content: item.theme});
                                    details.push({label: duration, content: getTimeDesc(item.end - item.start)});
                                } else if (item.status === "process") {
                                    icon = "icon-process";
                                    details.push({label: theme, content: item.theme});
                                    details.push({label: duration, content: `0${second}`});
                                } else if (item.status === "cancel") {
                                    icon = "icon-call-history-cancel";
                                    details.push({label: theme, content: none});
                                    details.push({label: duration, content: `0${second}`});
                                    details.push({label: reason, content: item.reason});
                                } else {
                                    icon = "icon-failed";
                                    details.push({label: theme, content: none});
                                    details.push({label: duration, content: `0${second}`});
                                    details.push({label: reason, content: item.reason});
                                }
                                details.push({label: creator, content: (item.creator && item.creator.length > 0) ? item.creator : none });

                                return <List.Item 
                                    actions={[
                                        <Space>
                                            <div onClick={(e) => this.meetJoinOnClick(e, item.code)}>
                                                <IconFont className="icon" type="icon-video"/><Button type="link">{t('modal.join_conference.history.join_again')}</Button>
                                            </div>
                                            <div onClick={(e) => {
                                                e.stopPropagation();
                                                dialHistory.del(item.key);
                                                this.setState({
                                                    flag: !this.state.flag,
                                                })
                                            }}>
                                                <IconFont className="icon" type="icon-delete"/><Button type="link">{t('modal.join_conference.history.delete')}</Button>
                                            </div>
                                        </Space>
                                    ]}
                                    >
                                    <List.Item.Meta
                                        title={<Row className="title" justify="space-between" align="middle" >
                                            <Col span={12}>
                                                <IconFont className="icon" type={icon}/>
                                                <span className="code" onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.setState({code: item.code});
                                                    }
                                                }>{item.code}</span>
                                            </Col>
                                            <Col span={12} className="time-col">
                                                <span className="time">{getDateDiff(moment().unix() - item.start, item.start)}</span>
                                            </Col>
                                        </Row>}
                                        description={
                                            <Descriptions className="descriptions" column={1}>
                                                {details.map((item, index) => <Descriptions.Item key={index} className="item" label={item.label}>{item.content}</Descriptions.Item>)}
                                            </Descriptions>
                                        }
                                    />
                                </List.Item>
                            }}
                        />
                    </Collapse.Panel>
                </Collapse>
            </div>
        </Modal>
    }

    // 头部菜单栏
    mkMeetHeader(meetStyle) {
        let that = this;
        let { fsm, barVisible, code, detail, sipStackStatus, mqttStatus, sipCallStartTime, sipRegSession } = this.state;
        let { t, user } = this.props;
        let fsmProc = fsm.test(FsmState.Call.PROCESS);
        let fsmStable = fsm.test(FsmState.Call.STABLE);
        let fsmCall = fsmProc || fsmStable;
        // 没有权限，提示告警（用户禁用了摄像头，麦克风）
        let warnComponent = [];
        if (mediaDevice.cameraPerm === mediaDevice.permission.forbidden || mediaDevice.microphonePerm === mediaDevice.permission.forbidden || mediaDevice.loudspeakerPerm === mediaDevice.permission.forbidden) {
            
            let cameraHelp = undefined;
            let microphoneHelp = undefined;
            let loudspeakerHelp = undefined;
            let helpOnClick = (e) => {
                e.stopPropagation();
                that.setState({permissionHelpVisible: true})
            }

            if (mediaDevice.cameraPerm === mediaDevice.permission.forbidden) {
                cameraHelp = <Button className="help" icon={<QuestionCircleOutlined /> } type="link" onClick={helpOnClick}>
                    {t('permission.warn.camera.help')}
                </Button>
            }

            if (mediaDevice.microphonePerm === mediaDevice.permission.forbidden) {
                microphoneHelp = <Button className="help" icon={<QuestionCircleOutlined />} type="link" onClick={helpOnClick}>
                    {t('permission.warn.microphone.help')}
                </Button>
            }
            
            if (mediaDevice.loudspeakerPerm === mediaDevice.permission.forbidden) {
                loudspeakerHelp = <Button className="help" icon={<QuestionCircleOutlined />} type="link" onClick={helpOnClick}>
                    {t('permission.warn.loudspeaker.help')}
                </Button>
            }
            
            warnComponent.push(
                <Tooltip key="permission" overlayClassName="tooltip-error-permission" 
                    placement="bottomRight" 
                    title={<div className="detail">
                        <Descriptions className="title" title={<span className="warning">{t('permission.warn.failed')}</span>} column={1}>
                            <Descriptions.Item label={t('permission.warn.camera.title')}>
                                <span className={mediaDevice.cameraPerm}>{t(`permission.warn.${mediaDevice.cameraPerm}`)}</span>
                                {cameraHelp}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('permission.warn.microphone.title')}>
                                <span className={mediaDevice.microphonePerm}>{t(`permission.warn.${mediaDevice.microphonePerm}`)}</span>
                                {microphoneHelp}
                            </Descriptions.Item>
                            <Descriptions.Item label={t('permission.warn.loudspeaker.title')}>
                                <span className={mediaDevice.loudspeakerPerm}>{t(`permission.warn.${mediaDevice.loudspeakerPerm}`)}</span>
                                {loudspeakerHelp}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>}
                >
                    <IconFont className="permission-icon" type="icon-permission-warning" />
                </Tooltip>
            )
        }

        // 个人信息
        let profileComponent = undefined;
        {
            let regStatus = sipRegSession && sipStackStatus === 'connected' ? "online" : "offline";
            let regTips = t('profile.register.tips.offline');
            let regClassName = "hidden";
            let sipStackTips = t('profile.sip_server.tips.reconnecting');
            let sipStackClassName = "hidden";
            let mqttTips = t('profile.mqtt_server.tips.reconnecting');
            let mqttClassName = "hidden";

            if (!sipRegSession) {
                regClassName = "show";
            }

            if (sipStackStatus !== 'connected') {
                if (sipStackStatus === 'disconnected') {
                    sipStackClassName = "show";
                    sipStackTips = t('profile.sip_server.tips.disconnected')
                } else if (sipStackStatus === 'reconnecting') {
                    sipStackClassName = "show";
                    sipStackTips = t('profile.sip_server.tips.reconnecting')
                }
            }

            if (mqttStatus !== 'connected') {
                if (mqttStatus === 'disconnected') {
                    mqttClassName = "show";
                    mqttTips = t('profile.mqtt_server.tips.disconnected')
                } else if (mqttStatus === 'reconnecting') {
                    mqttClassName = "show";
                    mqttTips = t('profile.mqtt_server.tips.reconnecting')
                }
            }

            profileComponent = (
                <Tooltip overlayClassName="tooltip-normal"
                    placement="bottomLeft" 
                    title={<div className="detail">
                        <Descriptions className="title" column={1}>
                            <Descriptions.Item label={t('profile.register.title')}>
                                <span className={regStatus}>{t(`common.${regStatus}`)}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('profile.sip_server.title')}>
                                <span className={sipStackStatus}>{t(`common.${sipStackStatus}`)}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label={t('profile.mqtt_server.title')}>
                                <span className={mqttStatus}>{t(`common.${mqttStatus}`)}</span>
                            </Descriptions.Item>
                        </Descriptions>
                    </div>} 
                >
                    <div className="profile">
                        <div className="status">
                            <span className={regStatus === 'online' && mqttStatus !== 'reconnecting' ? "dot dot-success" : "dot dot-failed"}/>
                            <span className="description">{user ? user.NickName : t('profile.anon')}</span>
                        </div>
                        <div className="tips">
                            <div className={"tip " + regClassName}>{regTips}</div>
                            <div className={"tip " + sipStackClassName}>{sipStackTips}</div>
                            <div className={"tip " + mqttClassName}>{mqttTips}</div>
                        </div>
                    </div>
                </Tooltip>
            )
        }

        // 主题
        let themeComponent = undefined;
        if (fsmCall) {
            code = code ? code.toString() : "";
            if (code && code.length > 0) {
                code = code.replace(/(?=(?!(\b))(\d{3})+$)/g," ")
            }
            themeComponent = <span className="theme">{code}</span>
        }

        // 计时器
        let timerComponent = (
            <Timer className="timer" start={sipCallStartTime}/>
        )

        // 退出
        let exitComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.exit.tooltip')} placement="bottomRight">
                <Popconfirm placement="bottomRight" title={t('operation.exit.confirm')} okText={t('operation.exit.ok')} cancelText={t('operation.exit.cancel')}
                    onConfirm={(e) => {
                        e.stopPropagation();
                        this.sipHangUp()
                        this.sipUnregister();
                    }} >
                    <IconFont className="icon" type="icon-exit" onClick={(e) => {e.stopPropagation();}}/>
                </Popconfirm>
            </Tooltip>
        )

        // 会议设置
        let settingComponent = undefined;
        if (!fsmCall) {
            settingComponent = <Tooltip overlayClassName="tooltip-normal" title={t('operation.setting.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-setting" onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        settingVisible: true,
                    })
                }}/>
            </Tooltip>
        }

        // 成员列表
        let memberListComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.member_list.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-list" onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        memberVisible: !this.state.memberVisible,
                    })
                }}/>
            </Tooltip>
        )

        // 媒体信息
        let rtcComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.media.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-rtc" onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        rtcInfoVisible: true,
                    })
                }}/>
            </Tooltip>
        )

        // 会议信息
        let detailComponent = (
            <Tooltip overlayClassName="tooltip-normal" title={t('operation.conference.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-info" onClick={(e) => {
                    e.stopPropagation();
                    this.setState({
                        detailVisible: true,
                    })
                }}/>
            </Tooltip>
        )
            
        // 分享
        let shareComponent = (
            <Tooltip overlayClassName={"tooltip-normal"} title={t('operation.share.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-share2" onClick={(e) => this.meetCopyOnClick(e, "conference", this.conferenceAddr(detail))}/>
            </Tooltip>
        )

        // 隐藏
        let hiddenComponent = (
            <Tooltip overlayClassName={"tooltip-normal"} title={t('operation.hidden.tooltip')} placement="bottomRight">
                <IconFont className="icon" type="icon-hidden" onClick={(e) => this.meetBarVisibleOnClick(e, 'icon')}/>
            </Tooltip>
        )
        
        return <Drawer
            className="drawer-header"
            placement={"top"}
            closable={false}
            visible={!!(meetStyle.opacity && barVisible)}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={400}
            key={"drawer_top"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="left">
                    <div className="status">
                        {warnComponent}
                        {profileComponent}
                    </div>
                </Col>
                <Col span={8} className="middle">
                    {themeComponent}
                    {timerComponent}
                </Col >
                <Col span={8} className="right">
                    {exitComponent}
                    {settingComponent}
                    {
                        fsmStable ? <div>
                            {memberListComponent}
                            {rtcComponent}
                            {detailComponent}
                            {shareComponent}
                            {hiddenComponent}
                        </div> : undefined
                    }
                </Col>
            </Row>
        </Drawer>
    }

    // 底部菜单栏
    mkMeetFooter(meetStyle) {
        let { fsm, barVisible, detail,
            videoEnable, audioEnable, localEnable, boardEnable, remotesEnable,
            layoutMode, menuLock, boardShare, screenShare, backgroudViewType
        } = this.state;
        let { t } = this.props;
        let leftIcons = [];
        let midIcons = [];
        let rightIcons = [];

        switch(fsm.curState()) {
            case FsmState.Call.INIT:
                midIcons.push(
                    <IconFont key="call" className="main" type="icon-call" 
                        onClick={(e) => {
                                e.stopPropagation();
                                this.setState({conferenceVisible: true})
                            }
                        } 
                    />
                )
                break;
            case FsmState.Call.PROCESS: {
                midIcons.push(
                    <Tooltip key="hangup" overlayClassName="tooltip-normal" title={t('operation.hangup.tooltip')} placement="top">
                        <IconFont key="hangup"  className="main" type="icon-hangup" onClick={(e) => {e.stopPropagation();this.sipHangUp('cancel');}}/>
                    </Tooltip>
                )
                break;
            }
            case FsmState.Call.STABLE:{
                leftIcons.push(
                    <Tooltip key="local" overlayClassName="tooltip-normal" title={t(localEnable ? 'operation.local_preview.tooltip.enable' : "operation.local_preview.tooltip.disable")} placement="topRight">
                        <IconFont className={localEnable ? "icon left selected" : "icon left"} type="icon-preview" onClick={(e) => this.menuIconToggleOnClick(e, "local")}/>
                    </Tooltip>
                )
                if (layoutMode === LayoutMode.OVERLAP) {
                    leftIcons.push(
                        <Tooltip key="room" overlayClassName="tooltip-normal" title={t(remotesEnable ? 'operation.remote_preview.tooltip.enable' : "operation.remote_preview.tooltip.disable")} placement="topRight">
                            <IconFont className={remotesEnable ? "icon left selected" : "icon left"} type="icon-room" onClick={(e) => this.menuIconToggleOnClick(e, "room")}/>
                        </Tooltip>
                    )
                    if (backgroudViewType === BackgroudViewType.VIDEO) {
                        if (detail && detail.BoardUrlHttps) {
                            leftIcons.push(
                                <Tooltip key="board" overlayClassName="tooltip-normal" title={t(boardEnable ? 'operation.board_preview.tooltip.enable' : "operation.board_preview.tooltip.disable")} placement="topRight">
                                    <IconFont className={boardEnable ? "icon left selected" : "icon left"} type="icon-whiteboard" onClick={(e) => this.menuIconToggleOnClick(e, "board")}/>
                                </Tooltip>
                            )
                        }
                    }
                }
                midIcons.push(
                    <Tooltip key="audio" overlayClassName="tooltip-normal" title={t(audioEnable ? 'operation.audio.tooltip.enable' : "operation.audio.tooltip.disable")} placement="top">
                        <IconFont className="offcut" type={audioEnable ? "icon-audio-enable" : "icon-audio-disable"} onClick={(e) => this.menuIconToggleOnClick(e, "audio")}/>
                    </Tooltip>
                )
                midIcons.push(
                    <Popconfirm key="hangup" placement="bottomRight" title={t('operation.hangup.confirm')} okText={t('operation.hangup.ok')} cancelText={t('operation.hangup.cancel')}
                        onConfirm={(e) => {
                            e.stopPropagation();
                            this.sipHangUp();
                        }} >
                        <IconFont className="main" type="icon-hangup" onClick={(e) => {e.stopPropagation();}}/>
                    </Popconfirm>
                )
                midIcons.push(
                    <Tooltip key="video" overlayClassName="tooltip-normal" title={t(videoEnable ? 'operation.video.tooltip.enable' : "operation.video.tooltip.disable")} placement="top">
                        <IconFont className="offcut" type={videoEnable ? "icon-video-enable" : "icon-video-disable"} onClick={(e) => this.menuIconToggleOnClick(e, "video")}/>
                    </Tooltip>
                )
                rightIcons.push(
                    <Dropdown key="layoutMode"
                        overlay={<Menu onClick={(e) => {this.menuItemOnClick(e, "layoutMode")}}>
                            <Menu.Item key={LayoutMode.OVERLAP}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.OVERLAP ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.overlap')}</span></span></Menu.Item>
                            <Menu.Item key={LayoutMode.SPLIT_4}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.SPLIT_4 ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.split4')}</span></span></Menu.Item>
                            <Menu.Item key={LayoutMode.SPLIT_9}><span className="menu-item"><IconFont type={layoutMode === LayoutMode.SPLIT_9 ? "icon-right" : ""} /><span className="description">{t('operation.layout_mode.items.split9')}</span></span></Menu.Item>
                        </Menu>}
                    >
                        <IconFont className="icon right" type="icon-layout" onClick={(e) => {e.stopPropagation();}}/>
                    </Dropdown>
                )
                if (layoutMode === LayoutMode.OVERLAP) {
                    rightIcons.push(
                        <Dropdown key="backgroudViewType"
                            overlay={<Menu onClick={(e) => this.menuItemOnClick(e, "backgroudViewType")}>
                                <Menu.Item key={BackgroudViewType.BOARD}><span className="menu-item"><IconFont type={backgroudViewType === BackgroudViewType.BOARD ? "icon-right" : ""} /><span className="description">{t('operation.background_view.items.board')}</span></span></Menu.Item>
                                <Menu.Item key={BackgroudViewType.VIDEO}><span className="menu-item"><IconFont type={backgroudViewType === BackgroudViewType.VIDEO ? "icon-right" : ""} /><span className="description">{t('operation.background_view.items.video')}</span></span></Menu.Item>
                            </Menu>}
                        >
                            <IconFont className="icon right" type="icon-background" onClick={(e) => {e.stopPropagation();}}/>
                        </Dropdown>
                    )
                }
                // rightIcons.push(
                //     <Dropdown key="lock"
                //         overlay={<Menu onClick={(e) => this.menuItemOnClick(e, "menuLock")}>
                //             <Menu.Item key={MenuState.LOCK}><span className="menu-item"><IconFont type={menuLock === MenuState.LOCK ? "icon-right" : ""} /><span className="description">{t('operation.menu.items.lock')}</span></span></Menu.Item>
                //             <Menu.Item key={MenuState.UNLOCK}><span className="menu-item"><IconFont type={menuLock === MenuState.UNLOCK ? "icon-right" : ""} /><span className="description">{t('operation.menu.items.unlock')}</span></span></Menu.Item>
                //         </Menu>}
                //     >
                //         <IconFont className="icon right" type={menuLock === MenuState.LOCK ? "icon-lock" : "icon-unlock" } onClick={(e) => {e.stopPropagation();}}/>
                //     </Dropdown>
                // )
                rightIcons.push(
                    <Dropdown key="board"
                        overlay={<Menu onClick={this.menuItemBoardShareOnClick}>
                            <Menu.Item key={BoardShareState.START}><span className="menu-item"><IconFont type={boardShare === BoardShareState.START ? "icon-right" : ""} /><span className="description">{t('operation.board.items.start')}</span></span></Menu.Item>
                            <Menu.Item key={BoardShareState.STOP}><span className="menu-item"><IconFont type={boardShare === BoardShareState.STOP ? "icon-right" : ""} /><span className="description">{t('operation.board.items.stop')}</span></span></Menu.Item>
                        </Menu>}
                    >
                        <IconFont className="icon right" type={boardShare === BoardShareState.START ? "icon-board-start" : "icon-board-stop" } onClick={(e) => {e.stopPropagation();}}/>
                    </Dropdown>
                )
		    if (adapter.isScreenShareSupported) {
		        rightIcons.push(
		            <Dropdown key="screen"
		                overlay={<Menu onClick={this.menuItemScreenShareOnClick}>
		                    <Menu.Item key={ScreenShareState.START}><span className="menu-item"><IconFont type={screenShare === ScreenShareState.START ? "icon-right" : ""} /><span className="description">{t('operation.screen.items.start')}</span></span></Menu.Item>
		                    <Menu.Item key={ScreenShareState.STOP}><span className="menu-item"><IconFont type={screenShare === ScreenShareState.STOP ? "icon-right" : ""} /><span className="description">{t('operation.screen.items.stop')}</span></span></Menu.Item>
		                </Menu>}
		            >
		                <IconFont className="icon right" type={screenShare === ScreenShareState.START ? "icon-screen-share-start" : "icon-screen-share-stop" } onClick={(e) => {e.stopPropagation();}}/>
		            </Dropdown>
		        )
		    }
                break;
            }
            default:
                break;
        }

        return <Drawer
            className="drawer-footer"
            placement={"bottom"}
            closable={false}
            visible={!!(meetStyle.opacity && barVisible)}
            mask={false}
            keyboard={false}
            height={64}
            zIndex={500}
            key={"drawer_bottom"}
            >
            <Row className="content" justify="space-around" align="middle">
                <Col span={8} className="icons">
                    {leftIcons}
                </Col>
                <Col span={8} className="icons">
                    {midIcons}
                </Col>
                <Col span={8} className="icons">
                    {rightIcons}
                </Col>
            </Row>
        </Drawer>
    }

    // 右边成员列表
    mkMeetRight(meetStyle) {
        let { height, width, memberVisible, layoutMode, detail, views, memberCurNum, memberTotalNum } = this.state;
        let { t } = this.props;
        let title = t('member_list.title') + (memberCurNum !== memberTotalNum ? `(${memberCurNum}/${memberTotalNum})` : "");
        return <Drawer
            className="drawer-right"
            placement="right"
            closable={true}
            visible={!!(meetStyle.opacity && memberVisible)}
            onClose={(e) => {this.setState({memberVisible: false})}}
            width={Math.min(width / 3, 500)}
            zIndex={600}
            destroyOnClose={true}
            key={"drawer_right"}
            title={title}
            >
                <MemberList
                    ref={(element) => {
                        this.memberListDOM = element;
                    }}
                    height={height}
                    conferenceInfo={detail}
                    conferenceLayout={layoutMode}
                    views={views}
                    memberViewOnChange={(views) => this.viewSelect(detail, views)}
                    closeOnClick={(e) => {this.setState({memberVisible: false})}}
                    onLoad={(cur, total) => {
                        this.setState({
                            memberCurNum: cur,
                            memberTotalNum: total,
                        })
                    }}
                />
        </Drawer>
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => {});
        window.removeEventListener("beforeunload", () => {});
        mediaDevice.removeListener(this.deviceOnChange);
        this.exit();
    }

    componentDidMount() {
        let that = this;
        this.setState({
            height: document.documentElement.clientHeight,
            width: document.documentElement.clientWidth,
        });
        mediaDevice.addListener(this.deviceOnChange);
        if (window.SIPml) {
            let logLevel = config.sipml.logLevel;
            window.SIPml.setDebugLevel(logLevel);
            window.SIPml.setLogFunction(Log.sipml5);
            window.SIPml.init(this.sipInit);

            Log.info(`set sipml log level: ${logLevel}`);
        }

        window.addEventListener("resize", () => {
            this.setState({
                height: document.documentElement.clientHeight,
                width: document.documentElement.clientWidth,
            })
        });

        window.addEventListener("beforeunload", () => {
            that.exit();
        });

        // 捕获全局异常，上传日志
        window.onerror = function (message, scriptUri, lineNo, columnNo, error) {
            Log.fatal(`global error: ${error.toString()}`)
            let { user, login } = that.props;
            try {
                let content = {
                    message: message,
                    scriptUri: scriptUri,
                    lineNo: lineNo,
                    columnNo: columnNo,
                    error: error.toString(),
                }
                let { name, info } = uploadInfo(user, login, version, adapter, mediaDevice);
                info = `[FATAL]\r\n${printObject(content)}\r\n\r\n` + info;
                Log.uploadFile(`1_fatal_error_${name}`, info, null, 'log');
            } catch (error) {
                Log.error(`catch error: ${error.toString()}`)
            }
        };
    }

    render(){
        let { height, width, fsm, views, stream, detail, backgroudViewType, backgroudViewIndex, barVisible,
            layoutMode, localEnable, boardEnable, remotesEnable, boardShare, } = this.state;
        let page = "";
        let loginStyle = {opacity: 0, width: 0, height: 0};
        let meetStyle = {opacity: 0, width: 0, height: 0};
        switch(fsm.curState()) {
            case FsmState.Login.INIT:
            case FsmState.Login.PROCESS:
            case FsmState.Login.OUT:
            case FsmState.Login.DONE:
                page = "login";
                loginStyle = {opacity: 1,  width: width || 0, height: height || 0}
                break;
            case FsmState.Call.INIT:
            case FsmState.Call.PROCESS:
            case FsmState.Call.STABLE:
            case FsmState.Call.HANGUP:
                page = "meet";
                meetStyle = {opacity: 1, width: width || 0, height: height || 0}
                break;
            default:
                return undefined;
        }

        return <Layout className={page}>
            <Layout.Content className="background">
                {/* 入会向导对话框 */}
                {this.mkConferenceWizzard()}
                {this.mkConferenceCreate()}
                {this.mkConferenceJoin()}
                {/* 其他对话框 */}
                {this.mkConferenceSetting()}
                {this.mkConferenceDetail()}
                {this.mkRtcInfo()}
                {this.mkPermissionHelp()}
                {/* 菜单栏 */}
                {this.mkMeetHeader(meetStyle)}
                {this.mkMeetFooter(meetStyle)}
                {this.mkMeetRight(meetStyle)}
                <Login
                    version={version.software}
                    style={loginStyle}
                    fsm={fsm}
                    sipRegisterFun={this.sipRegister}
                />
                <VideoLayout
                    style={meetStyle}
                    fsm={fsm}
                    views={views}
                    stream={stream}
                    detail={detail}
                    backgroudViewType={backgroudViewType}
                    backgroudViewIndex={backgroudViewIndex}
                    layoutMode={layoutMode}
                    boardShare={boardShare}
                    localEnable={localEnable}
                    boardEnable={boardEnable}
                    remotesEnable={remotesEnable}
                    videoDivOnMouseMove={this.videoDivOnMouseMove}
                    videoZoomInOnClick={this.videoZoomInOnClick}
                    videoSwitchOnClick={this.videoSwitchOnClick}
                    barVisible={barVisible}
                    barVisibleOnClick={this.meetBarVisibleOnClick}
                    localCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({localEnable: false});
                    }}
                    remotesCloseOnClick={(e) => {
                        e.stopPropagation();
                        this.setState({remotesEnable: false});
                    }}
                />
                <div>
                    <input id="dom_copy" v-model="dom_copy" style={{opacity: 0, position: "absolute"}} type="text"/>
                    <button id="dom_file_select" style={{display: "none", position: "relative", zIndex: 9999}}>Select</button>
                    <button id="dom_file_submit" style={{display: "none", position: "relative", zIndex: 9999}}>Submit</button>
                </div>
            </Layout.Content>
        </Layout>
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Main);


