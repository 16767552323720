import React, { Component } from 'react';
import { connect } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller';
import { List, Result, Spin, Tooltip, Tag, Space, Button } from 'antd';
import { withTranslation } from 'react-i18next'
import { IconFont } from '../../util/iconFont';
import { getLoginData, getUserData, } from '../../redux/reducers/login'
import { apiConferenceDetail, } from '../../api/conference';
import { Definition } from '../../api/structure';
import './memberList.css'

@withTranslation('translation', {withRef: true})
class MemberList extends Component {
    constructor(props){
        super(props);

        this.commonErrorHd = this.commonErrorHd.bind();
        this.handleInfiniteOnLoad = this.handleInfiniteOnLoad.bind(this);
        this.reloadData = this.reloadData.bind(this);

        this.state = {
            nodata: false,
            data: [],
            total: 0,
            pageNum: 1,
            pageSize: 50,
            loading: false,
            hasMore: true,
        };
    }

    commonErrorHd(dispatch, rsp, req) {
        console.error("", rsp)
        return true;
    }

    fetchData(memberFilter, init = false) {
        let that = this;
        let { conferenceInfo, onLoad } = this.props;

        if (conferenceInfo && memberFilter) {
            apiConferenceDetail(that.props, conferenceInfo, memberFilter, (dispatch, rsp, req) => {
                if (rsp.MemberList && rsp.MemberList.length > 0) {
                    let newData = [];
                    if (init) {
                        newData = rsp.MemberList;
                    } else {
                        newData = that.state.data.concat(rsp.MemberList);
                    }
                    // console.log("@@@@@@@@", !newData || newData.length === 0);
                    that.setState({
                        data: newData,
                        total: rsp.MemberTotalNum,
                        loading: false,
                        hasMore: newData.length < rsp.MemberTotalNum,
                        nodata: !newData || newData.length === 0,
                    })
                    if (onLoad) { 
                        onLoad(newData.length, rsp.MemberTotalNum)
                    }
                }
            }, that.commonErrorHd);
        } else {
            this.setState({
                nodata: true,
            })
        }
    }

    handleInfiniteOnLoad = () => {
        let { data, total, pageNum, pageSize } = this.state;
        let memberFilter = {
            pageNum: pageNum + 1,
            pageSize: pageSize,
        }
        if (data.length >= total) {
            this.setState({
                hasMore: false,
                loading: false,
            });
            return;
        } else {
            this.setState({
                loading: true,
                pageNum: pageNum + 1,
            });
        }
        setTimeout(() => this.fetchData(memberFilter), 1000);
    };

    reloadData() {
        let { pageNum, pageSize } = this.state;
        let memberFilter = {
            pageNum: pageNum,
            pageSize: pageSize,
        }
        this.fetchData(memberFilter, true);
    }

    mkMain() {
        let { loading, hasMore, data } = this.state;
        let { t, height, memberViewOnChange } = this.props;

        return <div className="infinite" style={{height: height - 64}}>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={this.handleInfiniteOnLoad}
                hasMore={!loading && hasMore}
                useWindow={false}
                >
                <List
                    className="list"
                    dataSource={data}
                    renderItem={(item) => {
                        let iconMe = undefined;
                        let iconView = undefined;
                        let iconDefinition = undefined;
                        let { user, views } = this.props;
                        let operation = undefined;
                        if (user.SipInfo.SipNum === item.SipNum) {
                            iconMe = <Tooltip title={t('member_list.me')}>
                                <IconFont className="icon" type="icon-me"/>
                            </Tooltip>;
                        }
                        
                        let findViewRet = views.findBySipNum(item.SipNum);
                        if (findViewRet) {
                            iconView = <Tooltip title={t('member_list.preview')}>
                                <IconFont className="icon" type="icon-view"/>
                            </Tooltip>;
                            let title = undefined;
                            let tag = undefined;
                            let color = undefined;
                            switch(findViewRet.definition) {
                                case Definition.HIGH:
                                    title = 'member_list.definition.high';
                                    tag = 'HD';
                                    color = '#9254de';
                                    break;
                                case Definition.LOW:
                                    title = 'member_list.definition.low';
                                    tag = 'LD';
                                    color = '#36cfc9';
                                    break;
                                default:
                                    break;
                            }
                            iconDefinition = <Tooltip title={t(title)}>
                                <Tag className="icon" color={color}>{tag}</Tag>
                            </Tooltip>;
                        }
            
                        if (findViewRet && user.SipInfo.SipNum !== item.SipNum) {
                            operation = <div className="operation">
                                <IconFont className="icon" type="icon-switch-video"/>
                                <Button className="button" type="link" onClick={(e) => {
                                    if (memberViewOnChange) {
                                        memberViewOnChange([item])
                                    }
                                }}>
                                    {t('member_list.operation.switch_video')}
                                </Button>
                            </div>;
                        }
                        operation = undefined;
                        
                        return <List.Item className="item" key={item.SipNum}>
                            <div className="content">
                                <div className="title">
                                    {/* <img className={"img "+ (item.State !== "joined" ? "disable" : "enable")} alt={item.NickName} src={item.avatarUrl}/> */}
                                    <span className={"name " + (item.State !== "joined" ? "disable" : "enable")}>{item.NickName}</span>
                                </div>
                                <div className="icons">
                                    <Space>
                                        { iconMe }
                                        { iconView }
                                        { iconDefinition }
                                        <Tooltip title={t(item.State === "joined" ? "member_list.status.joined" : "member_list.status.not_joined")}>
                                            <IconFont className="icon" type={item.State === "joined" ? "icon-online" : "icon-offline"}/>
                                        </Tooltip>
                                    </Space>
                                </div>
                            </div>
                            { operation }
                        </List.Item>
                    }}
                >
                    {loading && hasMore && (
                        <div className="loading">
                            <Spin />
                        </div>
                    )}
                </List>
            </InfiniteScroll>
        </div>
    }

    mkNoData() {
        let { t, closeOnClick } = this.props;
        return <Result
            className="not-find"
            status="warning"
            title={t('member_list.nodata.title')}
            subTitle={t('member_list.nodata.sub_title')}
            extra={<Space>
                <Button type="primary" onClick={(e) => {
                    this.reloadData();
                }}>{t('member_list.nodata.btn.refresh')}</Button>
                <Button type="primary" danger onClick={(e) => {
                    if (closeOnClick) {
                        closeOnClick();
                    }
                }}>{t('member_list.nodata.btn.close')}</Button>
            </Space>}
        />
    }

    componentDidMount() {
        this.reloadData();
    }

    render() {
        let { nodata } = this.state;
        let component = undefined;
        
        if (nodata) {
            component = this.mkNoData();
        } else {
            component = this.mkMain();
        }

        return component;
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(MemberList);