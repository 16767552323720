import React, { Component } from 'react';
import { connect } from 'react-redux'
import Slider from "../../slider/slider";
import { withTranslation } from 'react-i18next'
import { IconFont } from "../../../util/iconFont";
import { Log } from "../../../util/log";
import { LayoutMode } from "../../../api/structure";
import { getLoginData, getUserData, } from "../../../redux/reducers/login"
import { BoardShareState, BackgroudViewType, } from "../../../component/common/common";
import { message } from "../../../util/message";
import { State as FsmState } from "../../fsm/fsm";

import "./videoLayout.css";
import { config } from '../../../util/version';

@withTranslation()
class VideoLayout extends Component {
    constructor(props){
        super(props);

        this.state = {
            hiddenStyle: {opacity: 0, height: 0, width: 0, border: 'none'},
        }
    }

    setAudioRemoteDevice(deviceId) {
        let element = document.getElementById('dom_a_remote');
        if (element) {
            element.setSinkId(deviceId);
            return true;
        } else {
            return false;
        }
    }

    mkRemoteMenu(remote, nickName, style = undefined) {
        let { t, videoZoomInOnClick, videoSwitchOnClick } = this.props;

        return <div className="menu" style={style}>
            {/* 操作 */}
            <div className="operation" style={style}>
                <div className="col">
                    {/* 放大的图标，点击将会使视频放大 */}
                    <IconFont type="icon-zoom-in" className="icon" onClick={(e) => videoZoomInOnClick(e, remote)}/>
                    <div className="description">{t('video_layout.menu.operation.zoom_in')}</div> 
                </div>
                <div className="col">
                    {/* 切换的图标，点击将会切换视频源 */}
                    <IconFont type="icon-switch3" className="icon" onClick={(e) => videoSwitchOnClick(e, remote)}/>
                    <div className="description">{t('video_layout.menu.operation.switch')}</div> 
                </div>
            </div>
            {/* 显示该视频框的所属名称 */}
            {config.video.window.nickName.showMode === 'hover' ? <div className={"nickname"}>{nickName}</div> : undefined}
        </div>
    }

    mkRemotes(style, remotesComponent, backgroudViewType) {
        let { hiddenStyle } = this.state;
        let { t, views, stream, barVisible, backgroudViewIndex, remotesEnable, remotesCloseOnClick } = this.props;
        let remotesStyle = {opacity: 1, height: 200 + 16}
        if (!remotesEnable) {
            remotesStyle = {opacity: 0, height: 0}
        }
        remotesStyle.bottom = barVisible ? config.video.window.float.margin.larger : config.video.window.float.margin.small;
        let items = remotesComponent.map((item, index) => {
            let view = views.findByStreamId(index);
            let style = {opacity: 1, ...config.video.window.samll};

            if (!view) {
                style = hiddenStyle;
            } else {
                // 浮动布局（video为底）需要去掉当前最大化的那个video
                if (backgroudViewType === BackgroudViewType.VIDEO && index === backgroudViewIndex) {
                    style = hiddenStyle;
                }
            }
            
            let remote = `remote${index}`
            return <div key={remote} className={remote} style={style}>
                {/* 没有视频数据(没有选流)时的封面图 */}
                <img style={{height: style.height}} className={"poster " + (!view ? "show" : "hidden")} alt="" src="./images/video-poster.png"/>
                {/* 没有视频数据(没有选流)时的蒙层 */}
                <div style={{height: style.height, width: style.width}} className={"obscuration " + (!view ? "show" : "hidden")}/>
                {/* 菜单 */}
                {view ? this.mkRemoteMenu(remote, view.getNickName(), {height: style.height, width: style.width}) : undefined}
                {/* 显示该视频框的所属名称 */}
                {config.video.window.nickName.showMode === 'always' ? <div className={"nickname"}>{view ? view.getNickName() : undefined}</div> : undefined}
                {/* video标签 */}
                {item}
            </div>
        })

        return <div className="remotes-warpper" style={remotesStyle} onClick={(e) => {e.stopPropagation();}}>
            <div className="remotes">
                <Slider items={items} height={216} leftOnClick={(e) => {}} rightOnClick={(e) => {}} closeOnClick={remotesCloseOnClick}/>
            </div>
        </div>
    }

    mkOverlapBoard(style, remotesComponent, boardComponent) {
        let {fsm, videoZoomInOnClick} = this.props;
        let boardStyle = { height: style.height, width: style.width};
        let remotes = undefined;
        if (fsm.test(FsmState.Call.STABLE)) {
            remotes = this.mkRemotes(style, remotesComponent, BackgroudViewType.BOARD);
        } 

        return <div>
            <div className="board" style={boardStyle} onDoubleClick={(e) => videoZoomInOnClick(e, "board")}>
                {boardComponent}
            </div>
            {remotes}
        </div>
    }

    mkOverlapRemote(style, remotesComponent, boardComponent) {
        let { hiddenStyle } = this.state;
        let { fsm, user, detail, barVisible, boardEnable, backgroudViewIndex, videoZoomInOnClick } = this.props;
        let boardStyle = Object.assign({}, hiddenStyle);
        let remoteStyle = hiddenStyle;
        let remotes = undefined;

        if (boardEnable && fsm.test(FsmState.Call.STABLE)) {
            boardStyle = { opacity: 1, ...config.video.window.float};
        } 
        boardStyle.top = barVisible ? config.video.window.float.margin.larger : config.video.window.float.margin.small;

        if (fsm.test(FsmState.Call.STABLE)) {
            remoteStyle = { opacity: 1, height: style.height, width: style.width};
            remotes = this.mkRemotes(style, remotesComponent, BackgroudViewType.VIDEO);
        } 

        let show = "show"
        if (detail && detail.MemberList) {
            for (let i in detail.MemberList) {
                if (user.SipInfo.SipNum !== detail.MemberList[i].SipNum && detail.MemberList[i].State === 'joined') {
                    // 找到一个不是自己的其他成员
                    show = "hidden";
                    break;
                }
            }
        }
        
        return <div>
            <div className="video-overlap">
                <div className={`remote${backgroudViewIndex}`} style={remoteStyle}>
                {/* 会场只有自己时的封面图 */}
                <img style={{height: style.height}} className={"poster " + show} alt="" src="./images/video-poster.png"/>
                {/* 会场只有自己时的蒙层 */}
                <div style={{height: style.height, width: style.width}} className={"obscuration " + show}/>
                    {remotesComponent[backgroudViewIndex]}
                </div>
            </div>
            <div className="board float-window" style={boardStyle} onDoubleClick={(e) => videoZoomInOnClick(e, "board")}>
                {boardComponent}
            </div>
            {remotes}
        </div>
    }

    mkSplit(style, remotesComponent, boardComponent, layoutMode) {
        let { hiddenStyle } = this.state;
        let { views, boardShare, videoZoomInOnClick } = this.props;
        let mainClassName = undefined;
        let remoteStyle = {}
        let boardStyle = {};

        let split4Style = { opacity: 1, height: style.height / 2, width: style.width / 2 };
        let split9Style = { opacity: 1, height: style.height / 3, width: style.width / 3 };
        switch(layoutMode) {
            case LayoutMode.SPLIT_4: {
                mainClassName = "video-split-4";
                remoteStyle.remote0 = remoteStyle.remote1 = remoteStyle.remote2 
                    = remoteStyle.remote3
                    = split4Style;
                boardStyle = remoteStyle.remote4 = remoteStyle.remote5
                    = remoteStyle.remote6 = remoteStyle.remote7 = remoteStyle.remote8
                    = hiddenStyle;
                if (boardShare === BoardShareState.START) {
                    boardStyle = split4Style;
                    remoteStyle.remote3 = hiddenStyle;
                }
                break;
            }
            case LayoutMode.SPLIT_9: {
                mainClassName = "video-split-9";
                remoteStyle.remote0 = remoteStyle.remote1 = remoteStyle.remote2 = remoteStyle.remote3 
                    = remoteStyle.remote4 = remoteStyle.remote5 = remoteStyle.remote6 = remoteStyle.remote7
                    = remoteStyle.remote8 
                    = split9Style;
                boardStyle = hiddenStyle;

                if (boardShare === BoardShareState.START) {
                    boardStyle = split9Style;
                    remoteStyle.remote8 = hiddenStyle;
                }
                break;
            }
            default: {
                Log.error("unsupported layout mode: " + layoutMode);
                return undefined;
            }
        }

        let remotes = remotesComponent.map((item, index) => {
            let remote = `remote${index}`
            let style = remoteStyle[remote];
            let view = views.findByStreamId(index);
            return <div key={remote} className={remote} style={style}>
                {/* 没有视频数据(没有选流)时的封面图 */}
                <img style={{height: style.height}} className={"poster " + (!view ? "show" : "hidden")} alt="" src="./images/video-poster.png"/>
                {/* 没有视频数据(没有选流)时的蒙层 */}
                <div style={{height: style.height, width: style.width}} className={"obscuration " + (!view ? "show" : "hidden")}/>
                {/* 菜单 */}
                {view ? this.mkRemoteMenu(remote, view.getNickName()) : undefined}
                {/* 显示该视频框的所属名称 */}
                {config.video.window.nickName.showMode === 'always' ? <div className={"nickname"}>{view ? view.getNickName() : undefined}</div> : undefined}
                {/* video标签 */}
                {item}
            </div>
        })

        return <div className={mainClassName}>
            {remotes}
            <div className="board" style={boardStyle} onDoubleClick={(e) => videoZoomInOnClick(e, "board")}>
                <img alt="" style={{height: boardStyle.height, opacity: boardStyle.opacity}} className={"poster " + (boardShare === BoardShareState.STOP ? "show" : "hidden")} src="./images/board-poster.png"></img>
                <div alt="" style={{height: boardStyle.height, width: boardStyle.width, opacity: boardStyle.opacity}} className={"obscuration " + (boardShare === BoardShareState.STOP ? "show" : "hidden")}/>
                {boardComponent}
            </div>
        </div>
    }

    mkMain(style) {
        let { hiddenStyle } = this.state;
        let { t, login, user, fsm, barVisible, layoutMode, localEnable, detail, backgroudViewType, localCloseOnClick } = this.props;
        let localStyle = Object.assign({}, hiddenStyle);
        let localPreStyle = hiddenStyle;
        
        if (!fsm.test(FsmState.Call.STABLE)) {
            localPreStyle = { opacity: 1, height: style.height, width: style.width };
        } 

        if (localEnable && fsm.test(FsmState.Call.STABLE)) {
            localStyle = { opacity: 1, ...config.video.window.float};
        } 
        localStyle.top = barVisible ? config.video.window.float.margin.larger : config.video.window.float.margin.small;

        let src = ""
        if (detail && detail.BoardUrlHttps) {
            // 有url地址则选择url地址
            src = detail.BoardUrlHttps;
        } else if (user && login) {
            // 没有url地址则用token登录预加载
            src = user.BoardLoadUrlHttps + JSON.stringify({"Token": login.Token});
        }
        // 电子白板组件
        let boardComponent = <iframe 
            id="dom_b_remote0"
            className="iframe"
            title="board"
            style={{width:"100%", height: "100%", overflow:"visible"}}
            scrolling="no" 
            frameBorder="0"
            src={src}
            />

        // 视频组件
        let remotesComponent = Array.from({length: 9}).map((item, index) => {
            return <video className="video" height="100%" width="100%" id={`dom_v_remote${index}`} autoPlay muted/>
        })
        
        // 布局组件负责浮动布局、四分屏布局、九分屏布局构造
        let layoutComponent = undefined;
        switch(layoutMode) {
            case LayoutMode.OVERLAP: { 
                // 浮动布局
                if (backgroudViewType === BackgroudViewType.BOARD) {
                    // 浮动布局时，电子白板作为最大屏展示，其余浮在其上面
                    layoutComponent = this.mkOverlapBoard(style, remotesComponent, boardComponent);
                } else if (backgroudViewType === BackgroudViewType.VIDEO) {
                    // 浮动布局时，远端视频终端作为最大屏展示，其余浮在其上面
                    layoutComponent = this.mkOverlapRemote(style, remotesComponent, boardComponent);
                }
                break;
            }
            case LayoutMode.SPLIT_4:
            case LayoutMode.SPLIT_9: {
                // 四分屏、九分屏
                layoutComponent = this.mkSplit(style, remotesComponent, boardComponent, layoutMode);
                break;
            }
            default:
                Log.error("unsupported layout mode. ", layoutMode);
                break;
        }

        return <div>
            {/* 浮动布局 or 四分屏布局 or 九分屏布局 */}
            {layoutComponent}
            {/* 本端预览 */}
            <div className="local-preview" style={localPreStyle}>
                <video className="video" width="100%" height="100%" id="dom_v_local_preview" autoPlay muted />
            </div>
            {/* 本端浮动窗口 */}
            <div className="local float-window" style={localStyle} onClick={(e) => {e.stopPropagation();}}>
                <div className="menu">
                    <div className="operation">
                        <IconFont type="icon-close" className="icon" onClick={localCloseOnClick}/>
                        <div className="description">{t('video_layout.menu.operation.close')}</div> 
                    </div>
                </div>                
                <video className="video" style={localStyle} id="dom_v_local" autoPlay muted />
            </div>
            {/* 远端音频 */}
            <div className="audio-list">
                <audio id="dom_a_remote" autoPlay />
            </div>
        </div>;
    }

    componentDidMount() {
    
    }

    render() {
        let {videoDivOnMouseMove, barVisibleOnClick, style} = this.props;
        return <div className="meet-content" style={style} 
            // onMouseMove={videoDivOnMouseMove} 
            onClick={barVisibleOnClick}
        >
            {this.mkMain(style)}
        </div>
    }
}

let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(VideoLayout);