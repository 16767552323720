import React, { Component } from 'react';
import { connect } from "react-redux"
import moment from "moment";
import { Button, Result, Tabs, Progress, Row, Col, Select, Descriptions, Statistic, Modal, Input } from "antd";
import { AppstoreOutlined, AudioOutlined, CameraOutlined, FilterOutlined, FilterFilled, AudioFilled, CaretRightOutlined, PauseOutlined, LockOutlined, BugOutlined } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { device as mediaDevice } from '../media/device';
import { adapter } from '../media/adapter';
import { getLoginData, getUserData, } from "../../redux/reducers/login"
import { version, config } from '../../util/version';
import { Log } from "../../util/log"
import { message } from "../../util/message";
import { uploadInfo } from '../../util/logic';

import "./setting.css";


@withTranslation()
class Setting extends Component {
    constructor(props){
        super(props);

        this.deviceOnChange = this.deviceOnChange.bind(this);

        this.tabOnChange = this.tabOnChange.bind(this);

        this.cameraOnChange = this.cameraOnChange.bind(this);
        this.microphoneOnChange = this.microphoneOnChange.bind(this);
        this.microphoneTestOnClick = this.microphoneTestOnClick.bind(this);
        this.loudspeakerOnChange = this.loudspeakerOnChange.bind(this);
        this.loudspeakerTestOnClick = this.loudspeakerTestOnClick.bind(this);
        this.debugOnClick = this.debugOnClick.bind(this);

        this.state = {
            debug: false,

            cameraDeviceName: "",
            cameraPreviewError: "",

            microphoneTestStart: false,
            microphoneVolume: 0,
            microphoneDeviceName: "",
            
            loudspeakerTestStart: false,
            loudspeakerDeviceName: "",
        };
    }

    attachLoudspeakerDevice(deviceId) {
        let element = document.getElementById('dom_a_setting');
        if (element) {
            try {
                element.setSinkId(deviceId);
                console.log(`attach DOM<${element.id}> to loudspeaker device ${deviceId}`)
                return true;
            } catch (error) {
                console.error(error);
            }
        } 

        return false;
    }

    mediaStreamStop(stream, classify = '') {
        if (stream) {
            if (stream.stop) {
                stream.stop();
                console.log(`stop ${classify} stream sccess`);
            } else {
                let tracks = stream.getTracks() || [];
                tracks.forEach(track => {
                    if (track.stop){
                        track.stop();
                        console.log(`stop ${classify} ${track.kind} track of stream(${stream.id}) success`);
                    }
                });
            }
        }
    }

    deviceOnChange(event) {
        let { cameraDeviceId, cameras,
             microphoneDeviceId, microphones,
             loudspeakerDeviceId, loudspeakers
        } = this.props;
      
        this.cameraOnChange(cameraDeviceId, cameras);
        this.microphoneOnChange(microphoneDeviceId, microphones);
        this.loudspeakerOnChange(loudspeakerDeviceId, loudspeakers);
    }

    tabOnChange(key) {
        let { loudspeakerDeviceId } = this.props;
        switch(key) {
            case 'camera': {
                let cnt = 3;
                let loop = () => {
                    setTimeout(() => {
                        let element = document.getElementById('dom_v_setting');
                        if (element && this.cameraStream) {
                            window.attachMediaStream(element, this.cameraStream);
                        } else if (cnt > 0) {
                            cnt -= 1;
                            loop();
                        }
                    }, 500);
                }
                
                loop();
                break;
            }
            case 'microphone': {
                break;
            }
            case 'loudspeaker': {
                let ret = false;
                let cnt = 3;
                let loop = () => {
                    setTimeout(() => {
                        ret = this.attachLoudspeakerDevice(loudspeakerDeviceId)
                        if (!ret && cnt > 0) {
                            cnt -= 1;
                            loop();
                        }
                    }, 500);
                }
                
                loop();
                break;
            }
            default: {
                break;
            }
        }
    }

    cameraOnChange(deviceId, cameras = []) {
        let { t } = this.props;

        if (cameras.length === 0) {
            cameras = this.props.cameras;
        }

        let { onChange } = this.props;
        let findRet = cameras.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = cameras[0];
        }

        if (findRet) {
            this.mediaStreamStop(this.cameraStream, 'camera');
            this.cameraStream = null;
            adapter.getUserMedia({
                video: {
                    width: 1280, 
                    height: 720,
                    deviceId: { exact: findRet.deviceId }
                },
            }, (stream) => {
                // 成功的回调
                console.log(`open camera media stream(${stream.id}) success. `)
                this.cameraStream = stream;
                let element = document.getElementById('dom_v_setting');
                if (element) {
                    window.attachMediaStream(element, stream);
                }
                this.setState({
                    cameraPreviewError: undefined,
                })
            }, (error) => {
                // 失败的回调
                console.error("open camera media error: ", error);
                this.setState({
                    cameraPreviewError: t('setting.camera.media.error'),
                })
            })
        } else {
            console.warn("not find camera. deviceId:", deviceId)
        }

        console.log("camera onchange label:", findRet ? findRet.label : undefined)

        this.setState({
            cameraDeviceName: findRet ? findRet.label : undefined,
        })

        if (onChange) {
            onChange('camera', findRet ? findRet.deviceId : undefined);
        }
    }

    microphoneOnChange(deviceId, microphones = []) {
        if (microphones.length === 0) {
            microphones = this.props.microphones;
        }
        let { onChange } = this.props;
        let findRet = microphones.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = microphones[0];
        }

        this.setState({
            microphoneDeviceName: findRet ? findRet.label : undefined,
        })

        if (onChange) {
            onChange('microphone', findRet ? findRet.deviceId : undefined);
        }
    }

    microphoneTestOnClick() {
        let { microphoneTestStart } = this.state;
        let { microphoneDeviceId } = this.props;
        let that = this;
        
        this.mediaStreamStop(this.microphoneStream, 'microphone');
        this.microphoneStream = null;
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
        }

        if (!microphoneTestStart) {
            adapter.getUserMedia({
                audio: {
                    deviceId: { exact: microphoneDeviceId }
                },
                video: false,
                data: false,
            }, (stream) => {
                console.log(`microphone stream opened`, stream);
                this.microphoneStream = stream;
    
                if (!that.audioCtx) {
                    let audioContext = window.AudioContext || window.webkitAudioContext;
                    that.audioCtx = new audioContext();
                }
                let liveSource = that.audioCtx.createMediaStreamSource(stream);
                let levelChecker = that.audioCtx.createScriptProcessor(4096,1,1); 
                liveSource.connect(levelChecker); 
                levelChecker.connect(that.audioCtx.destination);
                levelChecker.onaudioprocess = function(e) { 
                    let buffer0 = e.inputBuffer.getChannelData(0); 
                    let volume = Math.max.apply(null, buffer0) * 100; 
    
                    // console.log("volume：" + Math.round(volume));
                    that.setState({
                        microphoneVolume: Math.round(volume),
                    })
                };
                
            }, (error) => {
                console.error("get microphone media error: ", error);
            })
        } 
        this.setState({
            microphoneTestStart: !microphoneTestStart,
            microphoneVolume: 0,
        })
    }

    loudspeakerOnChange(deviceId, loudspeakers = []) {
        if (loudspeakers.length === 0) {
            loudspeakers = this.props.loudspeakers;
        }
        let { onChange } = this.props;
        let findRet = loudspeakers.find((device) => {
            // console.log(device)
            return device.deviceId === deviceId
        });

        // 没有找到，则默认选第一个
        if (!findRet) {
            findRet = loudspeakers[0];
        }

        if (findRet) {
            let element = document.getElementById('dom_a_setting');
            if (element) {
                try {
                    element.setSinkId(findRet.deviceId);
                    console.log(`set loudspeaker to ${findRet.deviceId} on audio ${element.id} success`)
                } catch (error) {
                    console.error(error);
                }
            } else {
                console.warn(`set loudspeaker to ${findRet.deviceId} on audio element error`)
            }
        }

        this.setState({
            loudspeakerDeviceName: findRet ? findRet.label : undefined,
        })

        if (onChange) {
            onChange('loudspeaker', findRet ? findRet.deviceId : undefined);
        }
    }

    loudspeakerTestOnClick(e) {
        let { loudspeakerTestStart } = this.state;
        let element = document.getElementById('dom_a_setting');
        if (element) {
            if (!loudspeakerTestStart) {
                element.play();
            } else {
                element.pause();
            }
            this.setState({
                loudspeakerTestStart: !loudspeakerTestStart,
            })
        }
    }

    debugOnClick(e) {
        let that = this;
        let { debug, password } = this.state;
        let { t } = this.props;
        if (debug) {
            this.setState({
                debug: false,
                password: undefined,
            })
        } else {
            function onOk(close) {
                if (that.state.password === `vmeeting${moment().format("YYYYMMDD")}`) {
                    that.setState({debug: true})
                    if (typeof close === "function") {
                        close();
                    } else {
                        Modal.destroyAll();
                    }
                }
            }
            function onCancel(close) {
                that.setState({debug: false, password: undefined})

                if (typeof close === "function") {
                    close();
                } else {
                    Modal.destroyAll();
                }
            }
            Modal.confirm({
                title: t('setting.base.debug.title'), 
                content: (<div>
                    <Input.Password autoFocus={true} value={password} 
                        autoComplete="on" 
                        prefix={<LockOutlined />}
                        placeholder={t('setting.base.debug.password.placeholder')} 
                        onChange={(e) => {
                            that.setState({password: e.target.value})
                        }}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                onOk(); 
                            }
                        }} />
                </div>), 
                okText: t('setting.base.debug.btn.ok'),
                onOk: onOk,
                cancelText: t('setting.base.debug.btn.cancel'),
                onCancel: onCancel,
            })
        }
    }

    mkBase(style) {
        let { debug } = this.state;
        let { t, height, width, login, user, copyOnClick } = this.props;

        let expire = undefined;
        if (debug && login && user) {
            let thatTime = moment(login.Expire * 1000)
            thatTime.toDate()
            expire = <span className="expire">
                <span className="deadline">{thatTime.format('YYYY-MM-DD HH:mm:ss')}</span>
                (<Statistic.Countdown className="remaining" title={t('setting.base.descriptions.profile.expire_remaining')} value={thatTime.toDate()}/>)
            </span>
        }

        let component = <div className="tabpane-content tabpane-content-base" style={style}>
            <Descriptions className="descriptions" title={t('setting.base.descriptions.profile.title')} column={1}>
                {debug && user ? <Descriptions.Item label={t('setting.base.descriptions.profile.user_id')}>{user.UserId}</Descriptions.Item> : undefined}
                <Descriptions.Item label={t('setting.base.descriptions.profile.account')}>{user ? user.Account : undefined}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.profile.name')}>{user ? user.NickName : undefined}</Descriptions.Item>
                {debug && user && user.SipInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.sip_num')}>
                    {user && user.SipInfo ? user.SipInfo.SipNum : undefined}
                    <span className="operation"><Button type="link" 
                        onClick={(e) => {
                            if (copyOnClick) {
                                copyOnClick(e, "default", user && user.SipInfo ? user.SipInfo.SipNum : "")
                            }
                        }
                    }>{t('common.copy')}</Button></span>
                </Descriptions.Item> : undefined }
                {debug && user && user.SipInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.password')}>{user.SipInfo.Password}</Descriptions.Item> : undefined}
                {debug && user && user.WebInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.sip_domain')}>{user.WebInfo.SipRealm}</Descriptions.Item> : undefined}
                {debug && user && user.MqttInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.mqtt_account')}>{user.MqttInfo.Accout}</Descriptions.Item> : undefined}
                {debug && user && user.MqttInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.mqtt_password')}>{user.MqttInfo.Password}</Descriptions.Item> : undefined}
                {debug && user && user.MqttInfo ? <Descriptions.Item label={t('setting.base.descriptions.profile.mqtt_client_id')}>{user.MqttInfo.ClientId}</Descriptions.Item> : undefined}
                {debug && login ? <Descriptions.Item label={t('setting.base.descriptions.profile.token')}>{login.Token}</Descriptions.Item> : undefined}
                {debug && login ? <Descriptions.Item label={t('setting.base.descriptions.profile.refresh_token')}>{login.RefreshToken}</Descriptions.Item> : undefined}
                {debug && login ? <Descriptions.Item label={t('setting.base.descriptions.profile.expire')}>{expire}</Descriptions.Item> : undefined}
            </Descriptions>

            {
                debug ? <Descriptions className="descriptions" title={t('setting.base.descriptions.server.title')} column={1}>
                    <Descriptions.Item label={t('setting.base.descriptions.server.mqtt')}>{`wss://${user.MqttInfo.ServerWssHost}:${user.MqttInfo.ServerWssPort}`}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.base.descriptions.server.board')}>{user.BoardLoadUrlHttps}</Descriptions.Item>
                    <Descriptions.Item label={t('setting.base.descriptions.server.sip')}>{user.WebInfo.WebsocketUrl}</Descriptions.Item>
                    {
                        user && user.WebInfo && user.WebInfo.IceServers ? user.WebInfo.IceServers.map((ice, index) => {
                            return <Descriptions.Item key={index} label={t('setting.base.descriptions.server.ice') + (index + 1)}>{ice.Url} {ice.UserName} {ice.Password}</Descriptions.Item>
                        }) : undefined
                    }
                </Descriptions> : undefined
            }

            <Descriptions className="descriptions" title={t('setting.base.descriptions.feature.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.feature.device_list')}>{adapter.isDeviceListSupported ? t('permission.device_list.yes') : t('permission.device_list.no')}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.feature.screen_share')}>{adapter.isScreenShareSupported ? t('permission.screen_share.yes') : t('permission.screen_share.no')}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.feature.max_dial_history')}>{config.dialHistory.saveMax}</Descriptions.Item>
            </Descriptions>

            <Descriptions className="descriptions" title={t('setting.base.descriptions.env.title')} column={1}>
                <Descriptions.Item label={t('setting.base.descriptions.env.software_version')}>{version.software}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.env.sip_version')}>{version.sip}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.env.webrtc_version')}>{adapter.explorerInfo.type} {adapter.explorerInfo.version}</Descriptions.Item>
                <Descriptions.Item label={t('setting.base.descriptions.env.window')}>{width} * {height}</Descriptions.Item>
            </Descriptions>

        </div>
        return component;
    }
    
    mkCamera(style) {
        let { cameraDeviceName, cameraPreviewError } = this.state;
        let { t, cameraDeviceId, cameras } = this.props;

        let component = undefined;
        if (cameras.length > 0) {
            component = <div className="tabpane-content tabpane-content-camera" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="video-col">
                        <span className="name">{cameraDeviceName}</span>
                        <div className="video-wapper">
                            <video className="video" height="100%" width="100%" id="dom_v_setting" poster="./images/video-poster.png" autoPlay muted/> 
                        </div>
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="camera-col">
                        <div className="camera-wapper">
                            <div className="select-wapper">
                                <div className="title">{t('setting.camera.list.title')}</div>
                                <Select className="select" value={cameraDeviceId} onChange={(value) => this.cameraOnChange(value)}>
                                    {cameras.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                                <div className="title">{t('setting.camera.resolution.title')}</div>
                                <Select className="select" value={0} >
                                    <Select.Option key={0} value={0}>1280 * 720</Select.Option>
                                </Select>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div>
                    <span className={"tip-error " + (cameraPreviewError ? "show" : "hidden")}>{cameraPreviewError}</span>
                </div>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.camera.empty.title')}
                subTitle={t('setting.camera.empty.sub_title')}
            />
        }

        return component;
    }

    mkMicrophone(style) {
        let { microphoneTestStart, microphoneVolume, microphoneDeviceName } = this.state;
        let { t, width, microphoneDeviceId, microphones } = this.props;
        
        let customWave = "";
        if (width >= 1680) {
            customWave = " custom-1680 ";
        }   
        let component = undefined;
        if (microphones.length > 0) {
            component = <div className="tabpane-content tabpane-content-microphone" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="icon-col">
                        <span className="name">{microphoneDeviceName}</span>
                        <div className="icon-wapper">
                            <AudioFilled className="icon"/>
                            <div className={"wave " + customWave}>
                                <div className={"rectangle-1 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-2 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-3 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-4 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-5 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-6 " + customWave + (microphoneTestStart ? "start" : "stop")}/>
                            </div>
                        </div>
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="microphone-col">
                        <div className="microphone-wapper">
                            <div className="select-wapper">
                                <div className="title">{t('setting.microphone.list.title')}</div>
                                <Select className="select" value={microphoneDeviceId} disabled={microphoneTestStart} onChange={(value) => this.microphoneOnChange(value)}>
                                    {microphones.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                            </div>
                            <div className="btn-wapper">
                                <Button className="btn" type="primary" icon={!microphoneTestStart ? <CaretRightOutlined /> : <PauseOutlined />} onClick={this.microphoneTestOnClick}>{t('setting.microphone.btn.test')}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
                <div className="progress-volume">
                    <div>{t('setting.microphone.volume.title')}</div>
                    <Progress percent={microphoneVolume} status="normal" showInfo={false} strokeColor={{'0%': '#73d13d', '100%': '#ff4d4f'}}/>
                </div>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.microphone.empty.title')}
                subTitle={t('setting.microphone.empty.sub_title')}
            />
        }
        
        return component;
    }

    mkLoudspeaker(style) {
        let { loudspeakerTestStart, loudspeakerDeviceName } = this.state;
        let { t, width, loudspeakerDeviceId, loudspeakers } = this.props;

        let customWave = "";
        if (width >= 1680) {
            customWave = " custom-1680 ";
        }

        let component = undefined;
        if (loudspeakers.length > 0) {
            component = <div className="tabpane-content tabpane-content-loudspeaker" style={style}>
                <Row justify="space-between" align="top" >
                    <Col span={13} className="icon-col">
                        <span className="name">{loudspeakerDeviceName}</span>
                        <div className="icon-wapper">
                            <FilterFilled className="icon" rotate={90}/>
                            <div className={"wave " + customWave}>
                                <div className={"rectangle-1 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-2 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-3 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-4 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-5 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                                <div className={"rectangle-6 " + customWave + (loudspeakerTestStart ? "start" : "stop")}/>
                            </div>
                        </div>
                        <audio id="dom_a_setting" src='./mp3/test.mp3' loop autoPlay={false} />
                    </Col>
                    <Col span={1}/>
                    <Col span={10} className="loudspeaker-col">
                        <div className="loudspeaker-wapper">
                            <div className="select-wapper">
                                <div className="title">{t('setting.loudspeaker.list.title')}</div>
                                <Select className="select" value={loudspeakerDeviceId} disabled={loudspeakerTestStart} onChange={(value) => this.loudspeakerOnChange(value)}>
                                    {loudspeakers.map((device) => <Select.Option key={device.deviceId} value={device.deviceId}>{device.label}</Select.Option>)}
                                </Select>
                            </div>
                            <div className="btn-wapper">
                                <Button className="btn" type="primary" icon={!loudspeakerTestStart ? <CaretRightOutlined /> : <PauseOutlined />} onClick={this.loudspeakerTestOnClick}>{t('setting.loudspeaker.btn.test')}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        } else {
            component = <Result
                className="not-find"
                status="error"
                title={t('setting.loudspeaker.empty.title')}
                subTitle={t('setting.loudspeaker.empty.sub_title')}
            />
        }
        return component;
    }

    mkLog(style) {
        let that = this;
        let { uploading } = this.state;
        let { t, user, login } = this.props;

        let handler = Log.getFileHandler();
        let lines = handler ? handler.lines : [];

        let component = <div className="tabpane-content tabpane-content-log" style={style}>
            <div className="output-row" style={{maxHeight: style.maxHeight * 0.7}}>
                {
                    lines.length > 0 ? lines.map((line, index) => {
                        return <div key={index} className={"line" + line.level}>{line.content}</div>
                    }) : <div>{t(uploading ? 'setting.log.output.uploading' : 'setting.log.output.empty')}</div>
                }
            </div>
            <Button className="upload" type="primary" loading={uploading}  onClick={(e) => {
                e.stopPropagation();
                let { name, info } = uploadInfo(user, login, version, adapter, mediaDevice)
                that.setState({uploading: true});
                Log.uploadFile(name, info, null, 'log', 
                    (file, info) => {
                        setTimeout(() => {
                            message.success(t('setting.log.upload.message.success'))
                            that.setState({uploading: false});
                        }, 1000)
                    }, (error) => {
                        setTimeout(() => {
                            message.error(t('setting.log.upload.message.failed'))
                            that.setState({uploading: false, uploadError: error});
                        }, 1000)
                    }
                );
            }}>{t('setting.log.upload.btn')}</Button>
        </div>

        return component;
    }

    componentDidMount() {
        mediaDevice.addListener(this.deviceOnChange);
        setTimeout(() => {
            this.deviceOnChange();
        }, 1000);
    }

    componentWillUnmount() {
        mediaDevice.removeListener(this.deviceOnChange);
        this.mediaStreamStop(this.microphoneStream, 'microphone');
        this.mediaStreamStop(this.cameraStream, 'camera');
        if (this.audioCtx) {
            this.audioCtx.close();
            this.audioCtx = null;
        }
    }

    render() {
        let { t, height } = this.props;
        let style = {maxHeight: height * 0.7, minHeight: height * 0.6};
        let component = <Tabs className="tabs" tabPosition="left" onChange={this.tabOnChange}> 
            <Tabs.TabPane 
                tab={<span className="tabpane" onDoubleClick={this.debugOnClick}>
                        <AppstoreOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.base')}</span>
                    </span>}
                key="base"
            >
                {this.mkBase(style)}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <CameraOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.camera')}</span>
                    </span>}
                key="camera"
            >
                {this.mkCamera(style)}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <AudioOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.microphone')}</span>
                    </span>}
                key="microphone"
            >
                {this.mkMicrophone(style)}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <FilterOutlined className="icon" rotate={90}/>
                        <span className="title">{t('setting.tab.title.loudspeaker')}</span>
                    </span>}
                key="loudspeaker"
            >
                {this.mkLoudspeaker(style)}
            </Tabs.TabPane>
            <Tabs.TabPane
                tab={<span className="tabpane">
                        <BugOutlined className="icon"/>
                        <span className="title">{t('setting.tab.title.log')}</span>
                    </span>}
                key="log"
            >
                {this.mkLog(style)}
            </Tabs.TabPane>
            
        </Tabs>

        return <div className="content">
            {component}
        </div>
    }
}


let mapState = (state) => ({
    user: getUserData(state), 
    login: getLoginData(state),
});

export default connect(
    mapState, 
    null,
    null,
)(Setting);
