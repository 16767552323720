
export var MenuState = {
    UNLOCK: 0,
    LOCK: 1,
}

export var BoardShareState = {
    STOP: 0,
    START: 1,
}

export var LiveState = {
    STOP: 0,
    START: 1,
}

export var BackgroudViewType = {
    BOARD: 1,
    VIDEO: 2,
}

export var ScreenShareState = {
    STOP: 0,
    START: 1,
}
