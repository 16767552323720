import React, { Component } from 'react';
import { connect } from "react-redux"
import { Modal, Tabs } from "antd";
import { withTranslation } from 'react-i18next'
import { AudioOutlined, VideoCameraOutlined, DoubleRightOutlined, DoubleLeftOutlined, } from "@ant-design/icons";
import { IconFont } from "../../util/iconFont";

import "./rtc.css"
import "../common/paragraph.css";

@withTranslation()
class Rtc extends Component {
    constructor(props){
        super(props);

        this.state = {
        };
    }

    audioSenderInfo(aTrack) {
        let { t } = this.props;
        let codec = aTrack["codec"];
        let track = aTrack["track"];
        let local_candidate = aTrack["local-candidate"];
        let remote_candidate = aTrack["remote-candidate"];
        let outbound_rtp = aTrack["outbound-rtp"];
        return <div>
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    local_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{local_candidate.ip}:{local_candidate.port} {local_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remote_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr')}</span>
                            <span className="value">{remote_candidate.ip}:{remote_candidate.port} {remote_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    outbound_rtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{outbound_rtp.ssrc}(0x{outbound_rtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_sent')}</span>
                            <span className="value">{outbound_rtp.packetsSent}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_sent')}</span>
                            <span className="value">{((outbound_rtp.bytesSent + outbound_rtp.headerBytesSent) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.outgoing_bitrate')}</span>
                            <span className="value">{(outbound_rtp.outgoingBitRate / 1024).toFixed(2)} kbps</span>
                        </div>
                    </div> : undefined
                }
            </div>
        </div>;
    }

    audioReceiverInfo(aTrack) {
        let { t } = this.props;
        let codec = aTrack["codec"];
        let track = aTrack["track"];
        let local_candidate = aTrack["local-candidate"];
        let remote_candidate = aTrack["remote-candidate"];
        let inbound_rtp = aTrack["inbound-rtp"];
        return <div>
            <div className="paragraph">
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    local_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{local_candidate.ip}:{local_candidate.port} {local_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remote_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr')}</span>
                            <span className="value">{remote_candidate.ip}:{remote_candidate.port} {remote_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    inbound_rtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{inbound_rtp.ssrc}(0x{inbound_rtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{inbound_rtp.lastPacketReceivedTimestamp}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fec')}</span>
                            <span className="value">{inbound_rtp.fecPacketsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_lost')}</span>
                            <span className="value">{Math.abs(parseInt(inbound_rtp.packetsLost))}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_recv')}</span>
                            <span className="value">{inbound_rtp.packetsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_recv')}</span>
                            <span className="value">{((inbound_rtp.bytesReceived + inbound_rtp.headerBytesReceived)/ (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.incoming_bitrate')}</span>
                            <span className="value">{(inbound_rtp.incomingBitRate / 1024).toFixed(2)} kbps</span>
                        </div>
                    </div> : undefined
                }
                
            </div>
        </div>;
    }

    videoSenderInfo(vTrack) {
        let { t } = this.props;
        let codec = vTrack["codec"];
        let track = vTrack["track"];
        let local_candidate = vTrack["local-candidate"];
        let remote_candidate = vTrack["remote-candidate"];
        let outbound_rtp = vTrack["outbound-rtp"];
        return <div>
            <div className="paragraph">
                {/* <div className="title">
                    <span className="label">视频</span>
                </div> */}
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    track && track.frameWidth ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.definition')}</span>
                            <span className="value">{track.frameWidth} × {track.frameHeight}</span>
                        </div>
                    </div> : undefined
                }
                {
                    local_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{local_candidate.ip}:{local_candidate.port} {local_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remote_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr')}</span>
                            <span className="value">{remote_candidate.ip}:{remote_candidate.port} {remote_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    outbound_rtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{outbound_rtp.ssrc}(0x{outbound_rtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fir')}</span>
                            <span className="value">{outbound_rtp.firCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.nack')}</span>
                            <span className="value">{outbound_rtp.nackCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pli')}</span>
                            <span className="value">{outbound_rtp.pliCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_sent')}</span>
                            <span className="value">{((outbound_rtp.bytesSent + outbound_rtp.headerBytesSent) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.outgoing_bitrate')}</span>
                            <span className="value">{(outbound_rtp.outgoingBitRate / 1024).toFixed(2)} kbps</span>
                        </div>
                    </div> : undefined
                }
            </div>
        </div>
    }

    videoReceiverInfo(vTrack) {
        let { t } = this.props;
        let codec = vTrack["codec"];
        let track = vTrack["track"];
        let local_candidate = vTrack["local-candidate"];
        let remote_candidate = vTrack["remote-candidate"];
        let inbound_rtp = vTrack["inbound-rtp"];
        return <div>
            <div className="paragraph">
                {/* <div className="title">
                    <span className="label">视频</span>
                </div> */}
                {
                    codec ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.codec')}</span>
                            <span className="value">{codec.mimeType} {codec.clockRate}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pt')}</span>
                            <span className="value">{codec.payloadType}</span>
                        </div>
                    </div> : undefined
                }
                {
                    track && track.frameWidth ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.definition')}</span>
                            <span className="value">{track.frameWidth} × {track.frameHeight}</span>
                        </div>
                    </div> : undefined
                }
                {
                    local_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.local_addr')}</span>
                            <span className="value">{local_candidate.ip}:{local_candidate.port} {local_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
                {
                    remote_candidate ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.remote_addr')}</span>
                            <span className="value">{remote_candidate.ip}:{remote_candidate.port} {remote_candidate.protocol}</span>
                        </div>
                    </div> : undefined
                }
            </div>
            <div className="sub-paragraph">
                <div className="title">
                    <span className="label">{t('rtc.rtp')}</span>
                </div>
                {
                    track ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.msid')}</span>
                            <span className="value">{track.msid}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.cname')}</span>
                            <span className="value">{track.trackIdentifier}</span>
                        </div>
                    </div> : undefined
                }
                {
                    inbound_rtp ? <div>
                        <div className="description">
                            <span className="label">{t('rtc.ssrc')}</span>
                            <span className="value">{inbound_rtp.ssrc}(0x{inbound_rtp.ssrc.toString(16)})</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.timestamp')}</span>
                            <span className="value">{inbound_rtp.lastPacketReceivedTimestamp}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.fir')}</span>
                            <span className="value">{inbound_rtp.firCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.nack')}</span>
                            <span className="value">{inbound_rtp.nackCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.pli')}</span>
                            <span className="value">{inbound_rtp.pliCount}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_lost')}</span>
                            <span className="value">{Math.abs(parseInt(inbound_rtp.packetsLost))}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.packet_recv')}</span>
                            <span className="value">{inbound_rtp.packetsReceived}</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.bytes_recv')}</span>
                            <span className="value">{((inbound_rtp.bytesReceived + inbound_rtp.headerBytesReceived) / (1024 * 1024)).toFixed(2)} MB</span>
                        </div>
                        <div className="description">
                            <span className="label">{t('rtc.incoming_bitrate')}</span>
                            <span className="value">{(inbound_rtp.incomingBitRate / 1024).toFixed(2)} kbps</span>
                        </div>
                    </div> : undefined
                }
            </div>
        </div>
    }

    mkMain() {
        let { t, visible, asReports, arReports, vsReports, vrReports, onClose, height } = this.props;
        let tabPanes = [];
        let index = 0;
        if (asReports) {
            index = 0;
            for (let i in asReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <AudioOutlined className="left audio"/>
                                <DoubleRightOutlined className="right sender"/>
                            </span>
                            <span className="description">{t('rtc.tab.audio.send', {index: index})}</span>
                        </span>}
                    key={`audio_sender_${i}`}
                    >
                    {this.audioSenderInfo(asReports[i])}
                </Tabs.TabPane>)
            }
        }

        if (arReports) {
            index = 0;
            for (let i in arReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <AudioOutlined className="left audio"/>
                                <DoubleLeftOutlined className="right receiver"/>
                            </span>
                            <span className="description">{t('rtc.tab.audio.recv', {index: index})}</span>
                        </span>}
                    key={`audio_receiver_${i}`}
                    >
                    {this.audioReceiverInfo(arReports[i])}
                </Tabs.TabPane>)
            }
        }

        if (vsReports) {
            index = 0;
            for (let i in vsReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <VideoCameraOutlined className="left video"/>
                                <DoubleRightOutlined className="right sender"/>
                            </span>
                            <span className="description">{t('rtc.tab.video.send', {index: index})}</span>
                        </span>}
                    key={`video_sender_${i}`}
                    >
                    {this.videoSenderInfo(vsReports[i])}
                </Tabs.TabPane>)
            }
        }

        if (vrReports) {
            index = 0;
            for (let i in vrReports) {
                index += 1;
                tabPanes.push(<Tabs.TabPane
                    tab={<span className="title">
                            <span className="icons">
                                <VideoCameraOutlined className="left video"/>
                                <DoubleLeftOutlined className="right receiver"/>
                            </span>
                            <span className="description">{t('rtc.tab.video.recv', {index: index})}</span>
                        </span>}
                    key={`video_receiver_${i}`}
                    >
                    {this.videoReceiverInfo(vrReports[i])}
                </Tabs.TabPane>)
            }
        }

        let component = undefined;
        if (tabPanes.length > 0) {
            component = (
                <Tabs 
                    className="tab" 
                    style={{maxHeight: height * 0.7}}
                    tabPosition="left">
                        {tabPanes}
                </Tabs>)
        } 

        return <Modal
            className="rtc"
            title={<span className="title">
                <IconFont className="icon" type="icon-rtc-dark"/>
                <span>{t('rtc.title')}</span>
            </span>}
            width={"50%"}
            closable={true}
            maskClosable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            destroyOnClose={true}
        >
            <div className="content">
                {component}
            </div>
        </Modal>
    }

    componentDidMount() {

    }

    render() {
        return this.mkMain();
    }
}



let mapState = (state) => ({
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Rtc);