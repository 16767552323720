import { Log } from "../../util/log";

export let State = {
    // 登录状态
    Login: {
        INIT: "login_init",
        PROCESS: "login_process",
        OUT: "login_out",
        DONE: "login_done",
    },

    // 呼叫状态
    Call: {
        INIT: "call_init",
        PROCESS: "call_process",
        STABLE: "call_stable",
        HANGUP: "call_hangup",
    }
}

export class Fsm {
    /**
     * 构造函数
     * @method constructor
     * @for Fsm
     * @return {Fsm} 实例对象
     */
    constructor() {
        this.__state = State.Login.INIT;
        // this.__state = State.Call.INIT;
    }
    /**
     * 获取当前状态
     * @method curState
     * @for Fsm
     * @return {State} 返回当前状态
     */
    curState() {
        return this.__state;
    }
    /**
     * 状态转移
     * @method transfer
     * @for Fsm
     * @param {State} to 需要转移到的状态
     * @return {Void} 无
     */
    transfer(to) {
        if (this.__state !== to) {
            Log.info(`sip status ${this.__state} -> ${to}`)
            this.__state = to;
        }
    }
    /**
     * 测试当前状态
     * @method test
     * @for Fsm
     * @param {State} state 需要测试的状态
     * @return {Boolean} 成功返回true，否则false
     */
    test(state) {
        return this.__state === state;
    }
}