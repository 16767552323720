class Adapter {
    constructor() {
        this.__explorer = this.__getExplorerInfo();
        this.__isDeviceListSupported = navigator.mediaDevices && navigator.mediaDevices.enumerateDevices;
        this.__isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;

    }

    get isDeviceListSupported() {
        return this.__isDeviceListSupported;
    }

    get isScreenShareSupported() {
        return this.__isScreenShareSupported;
    }

    get explorerInfo() {
        return this.__explorer;
    }

    __getExplorerInfo() {
        let explorer = navigator.userAgent.toLowerCase();
        let type = undefined;
        let version = undefined;
        //ie
        if (explorer.indexOf("msie") >= 0) {
            version = explorer.match(/msie ([\d.]+)/)[1];
            type = "IE";
        }
        //firefox
        else if (explorer.indexOf("firefox") >= 0) {
            version = explorer.match(/firefox\/([\d.]+)/)[1];
            type = "Firefox";
        }
        //Chrome
        else if (explorer.indexOf("chrome") >= 0){
            version = explorer.match(/chrome\/([\d.]+)/)[1];
            type = "Chrome";
        }
        //Opera
        else if (explorer.indexOf("opera") >= 0){
            version = explorer.match(/opera.([\d.]+)/)[1];
            type = "Opera";
        }
        //Safari
        else if(explorer.indexOf("Safari") >= 0){
            version = explorer.match(/version\/([\d.]+)/)[1];
            type = "Safari";
        }
    
        return {
            type: type,
            version: version,
        }
    }

    getUserMedia(constraints, success, error) {
        if (navigator.mediaDevices.getUserMedia) {
            //最新的标准API
            navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error);
        } else if (navigator.webkitGetUserMedia) {
            //webkit核心浏览器
            navigator.webkitGetUserMedia(constraints, success, error)
        } else if (navigator.mozGetUserMedia) {
            //firfox浏览器
            navigator.mozGetUserMedia(constraints, success, error);
        } else if (navigator.getUserMedia) {
            //旧版API
            navigator.getUserMedia(constraints, success, error);
        }
    }

    getDisplayMedia(constraints, success, error) {
        if (navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices.getDisplayMedia(constraints).then(success).catch(error);
        } else {
            if (error) {
                error("not supported");
            }
        }
    }

    deviceOnChange(onChange) {
        if (navigator.mediaDevices) {
            navigator.mediaDevices.ondevicechange = onChange;
        }
    }

    deviceEnumerate(success, error) {
        if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.enumerateDevices().then(success).catch(error);
        } else {
            if (error) {
                error("not supported");
            }
        }
    }

}

let adapter = new Adapter();
export { adapter };
