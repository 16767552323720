import moment from "moment";
import { apiConferenceInfo } from "../../api/conference";
import { Log } from "../../util/log";
import { State as FsmState } from "../../component/fsm/fsm";
import { Kind, Dir, } from "../../component/media/stream";
import { device as mediaDevice } from "../../component/media/device";
import { version } from "../../util/version";
import { ScreenShareState,  } from "../../component/common/common";
import { message } from "../../util/message";

// 注册成功
function sipRegSessionOnConnected(e, This) {
    let { conferenceNum, fsm, stream, devicePreference } = This.state;
    let { t } = This.props;
    message.success(t('login.message.status.success'));
    fsm.transfer(FsmState.Login.DONE)
    setTimeout(() => {
        fsm.transfer(FsmState.Call.INIT);
        if (!conferenceNum) {
            This.setState({conferenceVisible: true});
            stream.preview.start(devicePreference.get('camera', mediaDevice));
        } else {
            // 登录的url中携带了会议号码，直接进入会议
            apiConferenceInfo(This.props, conferenceNum, (dispatch, rsp, req) => {
                This.setState({
                    number: rsp.SipCode,
                    code: rsp.ConferenceCode,
                })
                if (rsp.State === "inprogress") {
                    This.sipCall("call-audiovideo", rsp.SipCode, rsp.ConferenceCode);
                } else if (rsp.State === "finished") {
                    message.warn(t('call.message.api.info.status.ended', {conference: conferenceNum}))
                } else if (rsp.State === "tostart") {
                    message.warn(t('call.message.api.info.status.tostart', {conference: conferenceNum}))
                }
                
            }, (dispatch, rsp, req) => {
                switch(rsp.Status) {
                    case 51000:
                        message.error(t('call.message.api.info.status.not_exist', {conference: conferenceNum}))
                        break;
                    default:
                        message.error(t('call.message.api.info.error'))
                        break;
                }
                return true;
            })
        }
    }, 1000) 
}

// 注册失败/注销
function sipRegSessionOnTerminated(e, This) {
    let { fsm } = This.state;
    let { t } = This.props;
    let toast = null;
    let status = fsm.curState();

    Log.info(`sip code: ${e.getSipResponseCode()}`)

    switch(status){
        case FsmState.Login.INIT:
        case FsmState.Login.PROCESS:{
            switch(e.getSipResponseCode()){
                case 403:
                    toast = message.error(t('login.message.status.forbidden'));
                    break;
                case 200:
                    break;
                default:
                    toast = message.error(t('login.message.status.error'));
                    break;
            }
            break;
        }
        case FsmState.Login.OUT:
            toast = message.info(t('login.message.status.out'));
            break;

        default:
            break;
    }
    This.exit(false, true, toast);
}

// 呼叫成功
function sipCallSessionOnConnected(e, This) {

    let connected = () => {
        let { fsm, dialHistory, stream, views, devicePreference} = This.state;
        let { t } = This.props;
        let sessionId = e.session.getId();

        message.success(t('call.message.notify.status.connected'));
        This.setState({
            sipCallStartTime: moment().unix(),
        })
        fsm.transfer(FsmState.Call.STABLE);
        let content = {
            status: "success",
        }
        dialHistory.mod(sessionId, content)
        // 菜单栏超时自动隐藏
        // This.timerBarVisibleStart();
        // 本地预览切换到小窗口
        stream.preview.start(devicePreference.get('camera', mediaDevice), true);
        // 初始选流
        setTimeout(() => {
            Log.info("init view");
            This.viewInit(true, sessionId, views);
        }, 1000);
    }
    

    // 循环检查ice状态，没到connected-complete，不能继续下去
    // ice状态参考文档：https://developer.mozilla.org/zh-CN/docs/Web/API/RTCPeerConnection/iceConnectionState
    let cnt = 0;
    const loop = () => {
        let { fsm, iceStatus } = This.state;
        if (fsm.test(FsmState.Call.PROCESS)) {
            switch(iceStatus) {
                case "connected":
                case "completed": {
                    connected();
                    break;
                }
                
                default: {
                    This.connectedTimeout = setTimeout(() => {
                        This.connectedTimeout = null;
                        cnt += 1;
                        if (cnt < 600) {
                            loop();
                        } else {
                            Log.error("ice status is not connected, 60s timeout and hangup call");
                            This.sipHangUp('ice not connected');
                        }
                    }, 100);
                    break; 
                }
                        
            }
        }
    }

    loop();  
}

// 呼叫失败/挂断/取消
function sipCallSessionOnTerminated(e, This) {
    let {fsm, mqtt, stream, sipCallSessionId, dialHistory, reason, devicePreference} = This.state;
    let { t } = This.props;
    let status = fsm.curState();
    
    switch(status) {
        case FsmState.Call.PROCESS:
        case FsmState.Call.STABLE: {
            let content = undefined;
            if (status === FsmState.Call.PROCESS) {
                if (reason === 'cancel') {
                    message.info(t('call.message.notify.status.cancel'));
                    content = {
                        status: "cancel",
                        reason: t('call.reason.cancel'),
                    }
                } else {
                    message.error(t('call.message.notify.status.error'));
                    content = {
                        status: "failed",
                        reason: t('call.reason.error'),
                    }
                }
            } else {
                message.info(t('call.message.notify.status.ended'));
                content = {
                    end: moment().unix(),
                }
            }
            // This.timerBarVisibleStop();
            mqtt.stop();
            dialHistory.mod(e ? e.session.getId() : sipCallSessionId, content);
            stream.stop(Kind.ALL, Dir.ALL);
            stream.preview.start(devicePreference.get('camera', mediaDevice));
            fsm.transfer(FsmState.Call.INIT);
            stream.attach("dom_a_remote", null);
            This.setState({
                ...This.initCallSession(),
                ...This.initModal(),
            });
            if (This.__sip.hangupTimeout) {
                clearTimeout(This.__sip.hangupTimeout);
                This.__sip.hangupTimeout = null;
            }
            break;
        }
        default:
            break;
    }
}

// 新增音视频流信息
function sipCallSessionAvAdd(e, This) {
    let { stream } = This.state;
    let mediaStream = e.getParam();
    let pc = e.getUserData();
    if (pc) {
        stream.peerConnection = pc;
    }
    if (e.type === "m_stream_video_remote_added") {
        // video receiver 有多路视频(8)
        stream.add(mediaStream, Kind.VIDEO, Dir.RECV);
        stream.start((info) => This.setState(info));
        stream.reattachVideoRecv(mediaStream);
    } else if (e.type === "m_stream_audio_remote_added"){
        stream.add(mediaStream, Kind.AUDIO, Dir.RECV);
        stream.start((info) => This.setState(info));
        stream.attach("dom_a_remote", mediaStream);
    } else if (e.type === "m_stream_video_local_added")  {
        // video sender 有多路视频(2)
        stream.add(mediaStream, Kind.VIDEO, Dir.SEND);
        stream.start((info) => This.setState(info));
    } else if (e.type === "m_stream_audio_local_added")  {
        stream.add(mediaStream, Kind.AUDIO, Dir.SEND);
        stream.start((info) => This.setState(info));
    } else {
        Log.info("unsupported type. ", e.type)
        return;
    }

}

// 移除音视频流信息
function sipCallSessionAvRemoteRemoved(e, This) {
    let {stream} = This.state;
    switch(e.type){
        case "m_stream_audio_remote_removed":
            stream.stop(Kind.AUDIO, Dir.RECV);
            break;
        case "m_stream_audio_local_removed":
            stream.stop(Kind.AUDIO, Dir.SEND);
            break;
        case "m_stream_video_remote_removed":
            stream.stop(Kind.VIDEO, Dir.RECV);
            break;
        case "m_stream_video_local_removed":
            stream.stop(Kind.VIDEO, Dir.SEND);
            break;
        default:
            break;
    }
}

// 协议栈事件通知
function sipStackOnEvent(e /* SIPml.Stack.Event */, This) {
    Log.info("stack event: " + e.type);
    let { sipStack, fsm } = This.state;
    let { t } = This.props;
    let param = e.getParam() || {};
    let description = e.getDescription() || {};
    switch (e.type) {
        // 协议栈启动中
        case "starting": {
            message.loading(t('login.message.status.doing'));
            This.setState({
                sipStackStatus: 'connecting',
            })
            break;
        }
        // 协议栈启动完成
        case "restarted":
        case "started": {
            // catch exception for IE (DOM not ready)
            let { sipRegSession } = This.state;
            if (!sipRegSession) {
                try {
                    // LogIn (REGISTER) as soon as the stack finish starting
                    sipRegSession = sipStack.newSession("register", {
                        expires: 300,
                        ...configSessionListener(This),
                    });
                    Log.info(`new session(${sipRegSession.getClassfiy()}) ${sipRegSession.getId()}`)

                    setTimeout(() => sipRegSession.register(), 1000);
                    This.setState({
                        sipRegSession: sipRegSession,
                    })
                    fsm.transfer(FsmState.Login.PROCESS)

                } catch (e) {
                    Log.error("catch: " + e)
                }
            } 

            This.setState({
                sipStackStatus: 'connected',
            })
            
            break;
        }
        case "failed_to_start": {
            Log.warn("stack failed to start. reason: " + e.getPhrase());
            let toast = message.error(t('login.message.status.error'));
            This.exit(false, false, toast);
            break;
        }
        case "restarting": {
            Log.warn("stack restarting. reason: " + e.getPhrase());
            This.setState({
                sipStackStatus: 'reconnecting',
            })
            break;
        }
        case "stopping": 
        case "failed_to_stop":
        case "stopped": {
            Log.warn("stack stopped. reason: " + e.getPhrase());
            let toast = null;
            if (!fsm.test(FsmState.Login.INIT)) {
                if (!fsm.test(FsmState.Login.OUT)) {
                    toast = message.error(t('login.message.status.disconnected'));
                }
                This.exit(true, true, toast);
            }
            break;
        }
        
        // 收到呼叫
        case "i_new_call": {
            let { dialHistory, sipCallSession, sipConfig, number, code, fsm, theme, creator } = This.state;
            let cnt = 0;

            const call = () => {
                if (sipCallSession) {
                    Log.warn("new call incoming but there have a call exists, reject it");
                    e.newSession.reject();
                    return;
                } else {
                    let newSipCallSession = e.newSession;
                    let config = Object.assign(sipConfig, {
                        sip_headers: [],
                        ...configSessionListener(This),
                    })
                    let content = {
                        code: code, 
                        number: number,
                        creator: creator,
                        type: "call-audiovideo",
                        theme: theme,
                        start: moment().unix(),
                        end: undefined,
                        status: "process",
                    }
                    dialHistory.add(newSipCallSession.getId(), content)
                    newSipCallSession.accept(config);
                    This.setState({
                        sipCallSession: newSipCallSession,
                        sipCallSessionId: newSipCallSession.getId(),
                    })
                }
            }

            const loop = () => {
                if (!fsm.test(FsmState.Call.PROCESS)) {
                    Log.warn(`status is not right, ${fsm.curState()} != ${FsmState.Call.PROCESS}`)
                    This.newCallTimeout = setTimeout(() => {
                        This.newCallTimeout = null;
                        cnt += 1;
                        if (cnt < 6) {
                            loop();
                        } else {
                            e.newSession.reject();
                            let content = {
                                code: code, 
                                number: number,
                                type: "call-audiovideo",
                                theme: theme,
                                start: moment().unix(),
                                end: moment().unix(),
                                status: "failed",
                            }
                            dialHistory.add(e.newSession.getId(), content)
                        }
                    }, 500)
                } else {
                    call();
                }
            }

            loop();
            
            break;
        }

        // 权限已请求
        case "m_permission_requested": {
            let mediaType = e.getUserData() || {};
            Log.info("permission requested: " + mediaType.s_name)
            break;
        }
        
        // 权限已请求成功
        case "m_permission_accepted": {
            let mediaType = e.getUserData() || {};
            Log.info("permission accepted: " + mediaType.s_name)
            break;
        }

        // 权限请求失败
        case "m_permission_refused": {
            let mediaType = e.getUserData() || {};
            Log.error("permission refused: " + mediaType.s_name)
            message.error(t('permission.message.status.refused'));
            This.setState({
                permission: false,
            })

            // 呼叫过程中没有权限，直接挂断
            if (fsm.test(FsmState.Call.PROCESS)) {
                This.sipHangUp();
            }
            
            break;
        }

        // ICE 状态通知
        case "m_ice_status": {
            This.setState({
                iceStatus: param,
                iceDescription: description,
            })
            try {
                description = JSON.stringify(description)
            } catch (error) {
                description = "";
            }
            Log.info(`ice status: ${param} ${description}`);
            break;
        }

        default: 
            break;
    }
}

// 会话事件通知
function sipSessionOnEvent(e /* SIPml.Session.Event */, This) {
    let {fsm} = This.state;
    let {t} = This.props;
    if (e.type !== "i_info") {
        Log.info(`session(${e.session.getClassfiy()}) ${e.session.getId()} event ${e.type} on status ${fsm.curState()}`);
    }
    let { sipRegSession, sipCallSession } = This.state;
    switch (e.type) {
        case "connecting": {
            break;
        }
        case "connected": {
            if (e.session === sipRegSession) {
                sipRegSessionOnConnected(e, This);
            } else if (e.session === sipCallSession) {
                sipCallSessionOnConnected(e, This);
            } else {
                Log.info(`cur: ${e && e.session ? e.session.getId() : undefined} call: ${sipCallSession ? sipCallSession.getId() : undefined} reg: ${sipRegSession ? sipRegSession.getId() : undefined}`)
            }
            break;
        }
        case "terminating":
        case "terminated":{
            if (e.session === sipRegSession) {
                sipRegSessionOnTerminated(e, This);
            } else if (e.session === sipCallSession) {
                sipCallSessionOnTerminated(e, This);
            } else {
                Log.info(`cur: ${e && e.session ? e.session.getId() : undefined} call: ${sipCallSession ? sipCallSession.getId() : undefined} reg: ${sipRegSession ? sipRegSession.getId() : undefined}`)
            }
            break;
        }
        
        case "m_stream_audio_local_added": 
        case "m_stream_video_local_added":
        case "m_stream_audio_remote_added": 
        case "m_stream_video_remote_added": {
            if (e.session === sipCallSession) {
                // 当前会话是呼叫
                sipCallSessionAvAdd(e, This);
            } else {
                Log.info(`cur: ${e && e.session ? e.session.getId() : undefined} call: ${sipCallSession ? sipCallSession.getId() : undefined} reg: ${sipRegSession ? sipRegSession.getId() : undefined}`)
            }
            break;
        }
        case "m_stream_audio_local_removed":
        case "m_stream_video_local_removed":
        case "m_stream_audio_remote_removed":
        case "m_stream_video_remote_removed": {
            if (e.session === sipCallSession) { 
                // 当前会话是呼叫
                sipCallSessionAvRemoteRemoved(e, This)
            } else {
                Log.info(`cur: ${e && e.session ? e.session.getId() : undefined} call: ${sipCallSession ? sipCallSession.getId() : undefined} reg: ${sipRegSession ? sipRegSession.getId() : undefined}`)
            }
            break;
        }
        case "m_track_local_requested": {
            // Log.info("permission requested: " + e.getParam().s_name);
            // message.info("请求屏幕共享");
            break;
        }
        case "m_track_local_accepted": {
            let { screenShare } = This.state;
            // Log.info("permission accepted: " + e.getParam().s_name);
            if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE) && screenShare === ScreenShareState.STOP) {
                message.success(t('screen.message.notify.status.success'));
                This.setState({
                    screenShare: ScreenShareState.START,
                })
            }
            break;
        }
        case "m_track_local_refused": {
            let { screenShare } = This.state;
            // Log.info("permission refused: " + e.getParam().s_name);
            if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE)  && screenShare === ScreenShareState.STOP) {
                message.error(t('screen.message.notify.status.error'));
            }
            break;
        }
        case "m_track_local_ended": {
            let { screenShare } = This.state;
            // Log.info("track local ended: " + e.getParam().s_name);
            if (window.tmedia_cmp(e.getParam(), window.tmedia_type_e.SCREEN_SHARE) && screenShare === ScreenShareState.START) {
                sipCallSession.switch_video('video');
                This.setState({
                    screenShare: ScreenShareState.STOP,
                })
                message.info(t('screen.message.notify.status.ended'));
            }
            break;
        }
        
        // 收到响应消息
        case "i_ao_request":{
            if (e.session === sipCallSession) {
                switch(e.getSipResponseCode()) {
                    case 180:
                    case 183:
                        break;
                    default:
                        break;
                }
            }
            break;
        }
        default:
            break;
    }
}

// 配置会话事件通知
function configSessionListener(This) {
    return {
        events_listener: { events: "*", listener: (e) => sipSessionOnEvent(e, This) },
    }
}

// 配置协议栈事件通知
function configStackListener(This) {
    return {
        events_listener: { events: "*", listener: (e) => sipStackOnEvent(e, This) },
    }
}

// 初始化协议栈
export function sipInit() {
    let { t } = this.props;
    // 检查 WebRTC
    if (!window.SIPml.isWebRtcSupported()) {
        message.error(t('check.message.webrtc.nosupported'))
        return
    }

    // 检查 WebSocket
    if (!window.SIPml.isWebSocketSupported()) {
        message.error(t('check.message.websocket.nosupported'))
        return;
    }

    let sipConfig = {
        outbound_proxy_url: null,
        enable_rtcweb_breaker: true,
        enable_early_ims: false, // Must be true unless you"re using a real IMS network
        enable_media_stream_cache: false,
        ...configStackListener(this),
    };

    this.setState({
        sipConfig: sipConfig,
    })

    this.__sip = {};
}

// 初始化协议栈相关变量
export function sipInitStack() {
    return {
        sipStack: null,
        sipStackStatus: 'disconnected',
    }
}

// 初始化呼叫相关变量
export function sipInitCallSession() {
    return {
        sipCallSession: null,
        sipCallStartTime: 0,
    }
}

// 初始化注册相关变量
export function sipInitRegisterSession() {
    return {
        sipRegSession: null,
    }
}

// 更新配置
export function sipConfig(config) {
    let { sipStack, sipConfig, } = this.state;

    let newConfig = Object.assign(sipConfig, {
        ...config,
        ...configStackListener(this),
    });
    if (sipStack) {
        sipStack.setConfiguration(newConfig);
        this.setState({
            sipConfig: newConfig,
        })
    }
}

// 发起注册
export function sipRegister(sipRealm, sipUsername, sipPassword, websocketUrl, iceServers) {
    let { sipConfig, devicePreference } = this.state;
    let { t } = this.props;
    try {
        if (process.env.NODE_ENV === "production") {
            if (!window.location.protocol.match("https")) {
                message.error(t('check.message.http.ssl.nosupported'))
                return;
            }
        }

        if (!sipUsername || sipUsername.length === 0 || !sipPassword || sipPassword.length === 0) {
            message.error(t('sip.message.status.account.empty'))
            return;
        }
        
        // enable notifications if not already done
        if (window.webkitNotifications && window.webkitNotifications.checkPermission() !== 0) {
            window.webkitNotifications.requestPermission();
        }

        // update debug level to be sure new values will be used if the user haven"t updated the page
        window.SIPml.setDebugLevel("debug");
        // window.SIPml.setDebugLevel("info");
        let config = Object.assign(sipConfig, {
            websocket_proxy_url: websocketUrl,
            ice_servers: iceServers,
            realm: sipRealm,
            impi: sipUsername,
            impu: `sip:${sipUsername}@${sipRealm}`,
            password: sipPassword,
            display_name: sipUsername,   
            sip_headers: [
                { name: "User-Agent", value: "VMeeting-client-" + version.software },
                { name: "Organization", value: "angliancd.com" }
            ],
            video_input_device_id: devicePreference.get('camera', mediaDevice),
            audio_input_device_id: devicePreference.get('microphone', mediaDevice),
            audio_output_device_id: devicePreference.get('loudspeaker', mediaDevice),
            ...configStackListener(this),
        })

        // create SIP stack
        let sipStack = new window.SIPml.Stack(config);

        if (sipStack.start() !== 0) {
            Log.error("sip stack start failed.")
            message.error(t('sip.message.status.fail_to_start'))
        } else {
            this.setState({
                sipConfig: config,
                sipStack: sipStack,
            })
            return;
        }
    } catch (e) {
        Log.error("catch: " + e)
    }
}

// 发起注销
export function sipUnregister() {
    let {fsm, sipStack, sipCallSession, sipRegSession} = this.state;

    if (sipCallSession) {
        sipCallSession.hangup();
    }
    if (sipRegSession) {
        sipRegSession.unregister();
    }
    setTimeout(() => {
        if (sipStack) {
            sipStack.stop();
        }
    }, 2000)
    
    if (!fsm.test(FsmState.Login.OUT) && !fsm.test(FsmState.Login.INIT)) {
        fsm.transfer(FsmState.Login.OUT);
    }

}

// 发起呼叫
export function sipCall(type, number, code) {
    Log.info(`sip call ${type} -> "${code}"${number}`)
    let { fsm, stream, dialHistory, sipConfig, sipStack, sipCallSession } = this.state;
    let { t } = this.props;
    if (!fsm.test(FsmState.Call.INIT)) {
        Log.error(`status is not right, ${fsm.curState()} != ${FsmState.Call.INIT}`)
        return;
    }

    // 关闭本地预览
    stream.preview.stop();
    if (sipStack && !sipCallSession && (number && number.length >= 0)) {
        let config = Object.assign(sipConfig, {
            sip_headers: [],
            ...configSessionListener(this),
        })

        // create call session
        sipCallSession = sipStack.newSession(type, config);
        // make call
        if (sipCallSession.call(number) !== 0) {
            sipCallSession = null;
            return;
        }

        let sessionId = sipCallSession.getId();
        Log.info(`new session(${sipCallSession.getClassfiy()}) ${sessionId}`)
        let now = moment().unix();
        this.setState({
            sipCallSession: sipCallSession,
            sipCallSessionId: sessionId,
            sipCallStartTime: now,
        })
        message.loading(t('call.message.status.doing'))

        fsm.transfer(FsmState.Call.PROCESS)
        let content = {
            code: code, 
            number: number,
            type: type,
            theme: "",
            start: now,
            end: undefined,
            status: "process",
        }
        dialHistory.add(sipCallSession.getId(), content)
    }
}

// 发起挂断
export function sipHangUp(reason = 'normal') {
    let { fsm, sipCallSession } = this.state;
    let that = this;

    if ((fsm.test(FsmState.Call.STABLE) || fsm.test(FsmState.Call.PROCESS)) && sipCallSession) {
        Log.info("hangup call")
        sipCallSession.hangup({ 
            ...configSessionListener(this)
        });

        // 挂断时，若没有触发session回调，这里再次调用
        this.__sip.hangupTimeout = setTimeout(() => {
            sipCallSessionOnTerminated(null, that);
            that.__sip.hangupTimeout = null;
        }, 3000);

        this.setState({
            reason: reason,
        })
    }
}

// 静音
export function sipMute(type, mute) {
    let { sipCallSession } = this.state;
    if (sipCallSession) {
        sipCallSession.mute(type, mute);
    }
}

// 切换视频和共享屏幕
export function sipSwitchVideo(type) {
    let { sipCallSession } = this.state;
    if (sipCallSession) {
        sipCallSession.switch_video(type);
    }
}