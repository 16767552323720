import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Typography, Button, Row, Col, Card, Input, Form, } from "antd";
import { UserOutlined, LockOutlined, } from "@ant-design/icons";
import { withTranslation } from 'react-i18next'
import { saveLoginData, saveUserData, } from "../../redux/reducers/login"
import { apiLogin, apiUser, } from "../../api/login";
import { Log } from "../../util/log";
import { message } from "../../util/message";
import { adapter } from "../media/adapter";
import { State as FsmState } from "../fsm/fsm";

import "./login.css";

@withTranslation()
class Login extends Component {
    constructor(props){
        super(props);

        this.loginOnClick = this.loginOnClick.bind(this);
        this.loginInputOnChange = this.loginInputOnChange.bind(this);

        this.state = {
            username: "",
            password: "",
        }
    }

    loginInputOnChange(key, e) {
        // Log.info(key, e.target.value);
        let { t } = this.props;
        let value = e.target.value.replace(" ","");

        switch(key) {
            case "username": {
                let reg = /\w{1,20}$/;
                if (value.length > 0) {
                    if (!reg.test(value)) {
                        message.error(t('login.message.username.reg'));
                        return;
                    }
                }

                this.setState({
                    username: value,
                })
                break;
            }
            case "password": {
                this.setState({
                    password: value,
                })
                break;
            }
            default:
                break;
        }
    }

    loginOnClick(e) {

        console.log("login...");

        e.stopPropagation();

        let { username, password } = this.state;
        let { t, fsm, sipRegisterFun } = this.props;

        if (!username || username.length === 0) {
            message.error(t('login.message.username.empty'));
            this.loginUserNameDOM.focus();
            return;
        }

        if (!password || password.length === 0) {
            message.error(t('login.message.password.empty'));
            this.loginPasswordDOM.focus();
            return;
        }

        if (fsm.test(FsmState.Login.INIT)) {
            let req = {
                username: username,
                password: password,
                TokenType: 5, // Token类型固定为5，表示是PC web端登录
            }
            apiLogin(this.props, req, (dispatch, rsp1, req) => {
                delete rsp1.Status;
                delete rsp1.StatusMsg;
                dispatch(saveLoginData(rsp1));
                apiUser(this.props, rsp1.Token, (dispatch, rsp2, req) => {
                    delete rsp2.Status;
                    delete rsp2.StatusMsg;
                    dispatch(saveUserData(rsp2));
                    if (sipRegisterFun) {
                        sipRegisterFun(
                            // rsp.SipInfo.ServerDomain,
                            rsp2.WebInfo.SipRealm,
                            rsp2.SipInfo.SipNum,
                            rsp2.SipInfo.Password,
                            rsp2.WebInfo.WebsocketUrl,
                            rsp2.WebInfo.IceServers.map((item) => {
                                let server = {};
                                server["url"] = item.Url;
                                server[item.UserName] = item.Password;
                                return server;
                            })
                        )
                    }
                }, (dispatch, rsp, req) => {
                    message.error(t('login.message.api.user_info.error'), 5)
                    return true;
                })
            }, (dispatch, rsp, req) => {
                Log.error(`login: ${rsp}`);
                switch(rsp.Status) {
                    case 50000:
                    case 50001:
                        message.error(t('login.message.api.token.error'), 5)
                        break;
                    default:
                        break;
                }
                return true;
            })

        } else {
            Log.error(`login stats error: ${fsm.curState()}`);
        }
    }

    mkLoginIntroduction(opacity = 0) {
        let { version, type } = this.state;
        let { t } = this.props;
        return opacity ? <div className="introduction">
            <Typography.Title level={2}>
                {t('login.introduction.summary')}
            </Typography.Title>
            <Typography.Paragraph>
                <ul>
                    <li>
                        <Typography.Text>{t('login.introduction.feature1')}</Typography.Text>
                    </li>
                    <li>
                        <Typography.Text>{t('login.introduction.webrtc')}({type} {version})</Typography.Text>
                    </li>
                </ul>
            </Typography.Paragraph>
            <Typography.Text>
                {t('login.introduction.detail')}
            </Typography.Text>
        </div> : undefined;
    }

    mkLoginInput(opacity = 0) {
        let { username, password, } = this.state;
        let { t } = this.props;
        return opacity ? <div className="control">
            <Card bordered={false} title={<span>
                    <img alt="" className="logo" src="./logo192.png" />
                    <span className="title">{t('login.card.title')}</span>
                </span>}>
                <Form name="basic" className="content">
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: t('login.username.errortip')}]}
                        >
                        <div className="username">
                            <div className="title">{t('login.username.title')}</div>
                            <Input ref={(input) => this.loginUserNameDOM = input} 
                                value={username} 
                                autoFocus={true} 
                                size="large" 
                                placeholder={t('login.username.placeholder')} 
                                prefix={<UserOutlined />} 
                                onChange={(e) => this.loginInputOnChange("username", e)} 
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.loginPasswordDOM.focus();
                                    }
                                }} />
                        </div>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: t('login.password.errortip') }]}
                        >
                        <div className="password">
                            <div className="title">{t('login.password.title')}</div>
                            <Input.Password ref={(input) => this.loginPasswordDOM = input} 
                                autoComplete="on" 
                                value={password} 
                                size="large" 
                                placeholder={t('login.password.placeholder')} 
                                prefix={<LockOutlined />} 
                                onChange={(e) => this.loginInputOnChange("password", e)} 
                                onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                        this.loginOnClick(e)
                                    }
                                }} />
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <div className="login">
                            <Button type="primary" size="large" block onClick={this.loginOnClick}>{t('login.card.btn')}</Button>
                        </div>
                    </Form.Item>
                </Form>
            </Card>
        </div> : undefined;
    }

    componentDidMount() {
        this.setState(adapter.explorerInfo);
    }

    render() {
        let { t, style, status, version } = this.props;
        let headerComponent = <div className="header">
            <div className="version"><span>{t('version')}</span><span>{version}</span></div>
        </div>
        let bodyComponent = undefined;
        let height = Math.max(style.height - 64, 0);
        if (style.width < 1280) {
            bodyComponent = <Row justify="space-between" align="middle" style={{height: height}}>
                <Col span={1}></Col>
                <Col span={11}>{this.mkLoginIntroduction(style.opacity)}</Col>
                <Col span={11} style={{maxWidth: 360}}>{this.mkLoginInput(!!(style.opacity && status !== "success"))}</Col>
                <Col span={1}></Col>
            </Row>
        } else {
            bodyComponent = <Row justify="space-between" align="middle" style={{height: height}}>
                <Col span={5}></Col>
                <Col span={6}>{this.mkLoginIntroduction(style.opacity)}</Col>
                <Col span={2}></Col>
                <Col span={6} style={{maxWidth: 360}}>{this.mkLoginInput(!!(style.opacity && status !== "success"))}</Col>
                <Col span={5}></Col>
            </Row>
        }

        let footComponent = <div className="footer">
            <div className="copyright">{t('login.footer')}</div>
        </div>

        return <div className="login-content" style={style}>
            {headerComponent}
            {bodyComponent}
            {footComponent}
        </div>
    }
}


let mapState = (state) => ({
});

export default connect(
    mapState, 
    null,
    null,
    { forwardRef: true }
)(Login);