import { netRequestThunk2 } from '../util/netReqRD';

export const ACT_NET_CONFERENCE_CREATE = 'conferenceCreate';
export const ACT_NET_CONFERENCE_INFO = 'conferenceInfo';
export const ACT_NET_CONFERENCE_DETAIL = 'conferenceDetail';
export const ACT_NET_CONFERENCE_SCREEN_SET = 'conferenceScreenSet';
export const ACT_NET_CONFERENCE_BOARD_START = 'conferenceBoardStart';
export const ACT_NET_CONFERENCE_BOARD_STOP = 'conferenceBoardStop';

export const apiConferenceCreate = (props, title, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/create';
    let header = {
        Token: login.Token,
    }
    let body = {
        Title: title,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_CREATE, successFun, errorFun));
}

export const apiConferenceInfo = (props, code, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/info';
    let header = {
        Token: login.Token,
    }
    let body = {
        ConferenceCode: code,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_INFO, successFun, errorFun));
}

export const apiConferenceDetail = (props, conference, memberFilter, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/detail';
    let header = {
        Token: login.Token,
    }
    let body = {
        ConferenceId: conference.ConferenceId,
        MemberPageNum: memberFilter.pageNum,
        MemberPageSize: memberFilter.pageSize,
        MemberState: memberFilter.state,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_DETAIL, successFun, errorFun));
}

export const apiConferenceScreenSet = (props, conference, views, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/screen/set';
    let header = {
        Token: login.Token,
    }
    let body = {
        ConferenceId: conference.ConferenceId,
        LayoutId: 3,
        Type: 0,
        Views: views,
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_SCREEN_SET, successFun, errorFun));
}

export const apiConferenceBoardStart = (props, conference, sipNum, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/board/start';
    let header = {
        Token: login.Token,
    }
    let body = {
        ConferenceId: conference.ConferenceId,
        SipNum: sipNum
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_BOARD_START, successFun, errorFun));
}

export const apiConferenceBoardStop = (props, conference, sipNum, successFun = undefined, errorFun = undefined) => {
    const { dispatch, login } = props;
    const path = '/cs/v1/app/conference/board/stop';
    let header = {
        Token: login.Token,
    }
    let body = {
        ConferenceId: conference.ConferenceId,
        SipNum: sipNum
    }
    dispatch(netRequestThunk2(path, body, header, ACT_NET_CONFERENCE_BOARD_STOP, successFun, errorFun));
}
